import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import location from "../../assets/about-page/location.png";
import mail from "../../assets/gmail.png";
import facebook from "../../assets/footer/facebook.png";
import linkedIn from "../../assets/footer/linkedin.png";
import WatshApp from "../../assets/footer/watshapp.svg";
import Insta from "../../assets/footer/insta.svg";
import logo from "../../assets/logo.png";
import smallLogo from "../../assets/tm-logo.png";

function Footer() {
  const whatsappNumber = '8280040822'; 

  function handleWatsApp() {
    window.open(`https://wa.me/${whatsappNumber}`, '_blank');
}

function handleInsta() {
    window.open('https://www.instagram.com/the_tribes__', '_blank');
}

  return (
    <>
      <footer>
        <div className="footer-box">
          <div className="footer-opacity"></div>
          <div className="row align-items-start">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="footer-logo">
                <Link to="/">
                  <img
                    src={logo}
                    style={{ height: "38px" }}
                    alt=""
                    className="img-fluid footer-img-logo"
                  />
                </Link>
                {/* <p className="footer-small-logo">R</p> */}
              </div>
              <p className="m-0 footer-text">
              We aspire to not only foster cultural appreciation but also empower tribal communities by providing them with a sustainable source of income.
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 ">
              <div className="explore-list">
                <h6 className="footer-list-title">Explore</h6>
                <div className="footer-list">
                  <Link to="/">Home</Link>
                  <Link to="/about-us">About Us</Link>
                  <Link to="/product">Product</Link>
                  {/* <Link to="/blog">Portfolio</Link>
                  <Link>Contact Us</Link> */}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 ">
              <h6 className="footer-list-title">Contact us</h6>
              <div className="footer-list">
                <Link>
                  <div className="d-flex align-items-start">
                    <img
                      src={location}
                      style={{
                        height: "30px",
                        width: "47px",
                        objectFit: "cover",
                      }}
                      className="pe-4"
                      alt=""
                    />
                    <p className="m-0 footer-location">
                    Plot no - 358/759, Anatapur, Phulnakhara, 
                    <br /> Cuttack, Odisha – 754001
                    </p>
                  </div>
                </Link>
                <Link to="https://mail.google.com/mail/u/0/#search/thetribesarena%40gmail.com?compose=new">
                  <div className="d-flex align-items-start">
                    <img
                      src={mail}
                      style={{
                        height: "30px",
                        width: "46px",
                        objectFit: "cover",
                      }}
                      className="pe-3"
                      alt=""
                    />
                    <p className="m-0 footer-location">support@thetribes.in</p>
                  </div>
                </Link>
              </div>
              <div className="social-media-list">
                {/* <Link>
                  <img src={facebook} alt="" />
                </Link>
                <Link>
                  <img src={linkedIn} alt="" />
                </Link> */}
                <Link onClick={handleInsta}> 
                  <img src={Insta} alt="" />
                </Link>
                <Link onClick={handleWatsApp}>
                  <img src={WatshApp} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
