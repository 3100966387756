import React from "react";
import shop_item from "../../assets/shop/shop-item.png";
import star from "../../assets/arrivals/star.svg";
import { Link } from "react-router-dom";

function ShopCard({ name, brand, price, image, id }) {
  return (
    <>
      <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3" key={id}>
        <div className="shop-card">
          <Link to={`/product/product-detail/${id}`}>
            <img className="shop-img" src={image} alt="" />
          </Link>
          <div className="shop-card-content">
            <p className="shop-title">{name}</p>
            {/* <div className="d-flex align-items-center">
              <p className="brand-name m-0">{brand}</p>
              <div className="d-flex align-items-center">
                <p className="shop-rating m-0">4.4 </p>
                <img src={star} className="mb-3" alt="" />
              </div>
            </div> */}
            <div className="d-flex align-items-center">
              <p className="m-0 shop-price">Rs. {price}</p>
              <p className="m-0 shop-del">
                <del>Rs. {((price * 100) / 70).toFixed(0)}</del>
              </p>
              <p className="m-0 shop-off">(30% off)</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShopCard;
