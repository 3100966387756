import { useState } from "react";
import CartContext from "./CartContext";


const CartState = (props) => {
    const [cartSidebar, setCartSidebar] = useState(false);
    const [cartData, setCartData] = useState(null)
    return(
        <CartContext.Provider value={{setCartSidebar, cartSidebar, setCartData, cartData}}>
            {props.children}
        </CartContext.Provider>
    )
}

export default CartState;