import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { AiOutlineArrowRight } from "react-icons/ai";
import Aos from "aos";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import bannerImg from "../../assets/hero-bg.png";

function HeroContent({ image, title, content, mobileImage }) {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  function handleOnProduct() {
    navigate("/product");
  }
  return (
    <>
      <section
        className="hero-section"
        style={{
          backgroundImage: `url(${image})`,
          cursor: "pointer",
        }}
        onClick={handleOnProduct}
      >
        <div className="hero-overlay"></div>
        {/* <div data-aos="zoom-in" data-aos-duration="2000" data-aos-easing="linear"> */}
        <div className="hero-content text-center" style={{ color: "#FFF" }}>
          <h1 className="hero-title1">{title}</h1>
          <p className="hero-text1">{content}</p>
          {/* <span className="shop-now-btn">
            <button>Shop Now</button>
            <AiOutlineArrowRight className="shop-now-btn-arrow" />
          </span> */}
        </div>
        {/* </div> */}
        <div className="hero-wrapper-1"></div>
        <div className="hero-wrapper-2"></div>
      </section>
      <section
        className="hero-section-"
        style={{
          backgroundImage: `url(${mobileImage})`,
          cursor: "pointer",
          // backgroundSize:"103% 100%"
          // backgroundRepeat: "no-repeat",
          // backgroundSize:"contain",
          // width: "100%",
          // height: "100vh",
          // backgroundPosition: "center"
        }}
        onClick={handleOnProduct}
      >
        <div className="hero-overlay"></div>
        {/* <div data-aos="zoom-in" data-aos-duration="2000" data-aos-easing="linear"> */}
        <div className="hero-content text-center" style={{ color: "#FFF" }}>
          <h1 className="hero-title1">{title}</h1>
          <p className="hero-text1">{content}</p>
          {/* <span className="shop-now-btn">
            <button>Shop Now</button>
            <AiOutlineArrowRight className="shop-now-btn-arrow" />
          </span> */}
        </div>
        {/* </div> */}
        <div className="hero-wrapper-1"></div>
        <div className="hero-wrapper-2"></div>
      </section>
    </>
  );
}

function AboutSlider() {
  const [allBanner, setAllBanner] = useState([]);
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const getAll = async () => {
    try {
      const res = await axios.get(
        `https://tribes-backend-server.vercel.app/about/banner/all`
      );
      console.log(res);
      setAllBanner(res?.data?.data?.banners);
    } catch (error) {}
  };
  useEffect(() => {
    getAll();
  }, []);
  console.log(allBanner);
  return (
    <>
      <Slider {...settings}>
        {allBanner.length === 0 ? (
          <>
            {/* <div>
              <HeroContent image={bannerImg} />
            </div>
            <div>
              <HeroContent image={bannerImg} />
            </div>
            <div>
              <HeroContent image={bannerImg} />
            </div>
            <div>
              <HeroContent image={bannerImg} />
            </div> */}
          </>
        ) : (
          allBanner?.map((e) => (
            <div>
              <HeroContent
                mobileImage={e?.phoneBannerImage?.url || bannerImg}
                image={e?.bannerImage?.url || bannerImg}
                title={e?.title}
                content={e?.content}
              />
            </div>
          ))
        )}
      </Slider>
    </>
  );
}

export default AboutSlider;
