import React, { useState } from "react";
import DownArrow from "../../assets/select-bg.svg";
import { Slider } from "@mui/material";

function CustomDropdown({
  handlePriceFilter,
  resetProducts,
  buttonName,
  isOpen2,
  handleChange,
  setIsOpen2,
  applyFilter,
  handleLowPriceFilter, 
  handleHighPriceFilter,
  priceRange,
}) {
  const [isOpen1, setIsOpen1] = useState(false);
  


  const toggleDropdown1 = () => {
    setIsOpen2(false);
    setIsOpen1(!isOpen1);
  };
  const toggleDropdown2 = () => {
    setIsOpen1(false);
    setIsOpen2(!isOpen2);
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return (
    <>
      <div className="text-center">
        <div className="custom-dropdown">
          <div className="selected-option" onClick={toggleDropdown1}>
            Filter
            <img src={DownArrow} alt="" />
          </div>
          <div
            className="selected-option"
            style={{ position: "relative" }}
            onClick={toggleDropdown2}
          >
            {buttonName}
            <img src={DownArrow} alt="" />
            {isOpen2 && (
              <div className="dropdown-options-second">
                <div className="option" onClick={handleLowPriceFilter}>Price (lowest first)</div>
                <div className="option" onClick={handleHighPriceFilter}>Price (highest first)</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isOpen1 && (
        <div className="dropdown-options">
          <div className="container">
            <div className="row">
              {/* <div className="col-sm-12 col-md-12 col-lg-4">
                <h6 className="filter-option-title">Category</h6>
                <ol className="filter-option">
                  <li>
                    <input type="checkbox" style={{ marginRight: "8px" }} />
                    <span>Curly</span>
                  </li>
                  <li>
                    <input type="checkbox" style={{ marginRight: "8px" }} />
                    <span>Curly</span>
                  </li>
                  <li>
                    <input type="checkbox" style={{ marginRight: "8px" }} />
                    <span>Curly</span>
                  </li>
                </ol>
              </div> */}
              <div className="col-sm-12 col-md-12 col-lg-4">
                <h6 className="filter-option-title">Price</h6>
                <Slider
                  value={priceRange}
                  min={0}
                  defaultValue={[0, 5000]}
                  max={5000}
                  onChange={handleChange}
                  valueLabelDisplay="auto"
                />
                <ol className="filter-option">
                  <li>
                    {/* <input type="checkbox" style={{ marginRight: "8px" }} /> */}
                    Selected Price &nbsp;
                    <span> ₹&nbsp;{priceRange[0]}.00</span>
                    &nbsp;-&nbsp;
                    <span> ₹&nbsp;{priceRange[1]}.00</span>
                  </li>
                  {/* <li>
                    <input type="checkbox" style={{ marginRight: "8px" }} />
                    <span> ₹1,000.00</span>
                    &nbsp;-&nbsp;
                    <span>₹1,999.99</span>
                  </li>
                  <li>
                    <input type="checkbox" style={{ marginRight: "8px" }} />
                    <span>₹2,000.00 </span>
                    &nbsp;-&nbsp;
                    <span> ₹2,999.99</span>
                  </li>
                  <li>
                    <input type="checkbox" style={{ marginRight: "8px" }} />
                    <span>₹3,000.00 </span>
                    &nbsp;-&nbsp;
                    <span> ₹3,999.99</span>
                  </li>
                  <li>
                    <input type="checkbox" style={{ marginRight: "8px" }} />
                    <span>₹4,000.00 </span>
                    &nbsp;-&nbsp;
                    <span> ₹4,999.99</span>
                  </li>
                  <li>
                    <input type="checkbox" style={{ marginRight: "8px" }} />
                    <span>₹5,000.00 </span>
                    &nbsp; &nbsp;
                    <span> and above</span>
                  </li> */}
                </ol>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button className="apply-btn" onClick={applyFilter}>Apply</button>
            <button className="clear-btn" onClick={resetProducts}>Clear</button>
          </div>
        </div>
      )}
    </>
  );
}

export default CustomDropdown;
