import React, { useEffect, useState } from "react";
import "./blog.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { baseUrl } from "../../context/data";

function BlogDetailPage() {
  const [blog, setBlog] = useState(null);
  const params = useParams();
  const blogId = params.id;
  async function getBlog() {
    try {
      const res = await axios({
        method: "get",
        url: baseUrl + `/blog/getblog/${blogId}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setBlog(res.data.data);
    } catch (e) {}
  }
  useEffect(() => {
      getBlog();
  }, []);
  if (blog === null) {
    return <></>;
  }
  const date = moment(blog.createdAt).format("DD/MM/YYYY")
  return (
    <>
      <section className="blog-detail-section">
        <div className="blog-img">
          <img src={blog.displayImage[0].url} className="img-fluid" alt="" />
        </div>
        <h2 className="blog-title">{blog.title}</h2>
        <p className="writer-blog m-0">by Gautam</p>
        <p className="m-0 blog-date">Date:- {date}</p>
        <p className="blog-detail-text m-0">
          {blog.content}
        </p>
      </section>
    </>
  );
}

export default BlogDetailPage;
