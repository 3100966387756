import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import './track.css'

function Steps() {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: 'Step 1: Shipped',
      content: 'Item shipped from Warehouse A.',
    },
    {
      label: 'Step 2: Sorting',
      content: 'Item arrived at Sorting Facility B.',
    },
    {
      label: 'Step 3: In Transit',
      content: 'Item is in transit to Destination City, Country.',
    },
    {
      label: 'Step 4: Delivered',
      content: 'Item delivered to the recipient.',
    },
  ];

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <div>
      <div className="tracking-container">
      <div className="details-container">
        <h2>Details for Tracking ID: ABC123</h2>
        {/* Displaying content based on the active step */}
        <p className="step-content">{steps[activeStep].content}</p>
        <div className="stepper">
          <button className="payment-btn" disabled={activeStep === 0} onClick={handleBack}>
            Back
          </button>
          <div className="step-label">{steps[activeStep].label}</div>
          <button className="payment-btn" disabled={activeStep === steps.length - 1} onClick={handleNext}>
            Next
          </button>
        </div>
        {/* Rest of the component */}
      </div>
    </div>
    </div>
  );
}

export default Steps;
