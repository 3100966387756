import React, {
  useContext,
  useEffect,
  useState /* , { useEffect, useState } */,
} from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import axios from "axios";
import { baseUrl } from "../context/data";
import { toast } from "react-toastify";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import Steps from "./Stepper";
import CartData from "./CartData";
import {
  Button,
  Modal,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Input,
  FormControl,
  InputLabel,
  Radio,
} from "@mui/material";
import ScreenLoader from "../components/loader/ScreenLoader";
import emptyCart from "../assets/empty-cart.svg";
import CartContext from "../context/CartContext/CartContext";

function Cart() {
  const cartSide = useContext(CartContext);
  const {cartData, setCartData} = cartSide
  // const [cartData, setCartData] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [applyCoupon, setApplyCoupon] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [validCoupon, setValidCoupon] = useState(false);
  const [selectedAdd, setSelectedAdd] = useState({});
  const [shippingAddress, setShippingAddress] = useState([]);
  // console.log(selectedAdd, shippingAddress);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [selectMethod, setSelectMethod] = useState("");
  console.log(selectedOption);
  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleOptionChange = (item, id) => {
    setSelectedOption(id);
    setSelectedAdd(item);
  };
  async function getCart() {
    try {
      const res = await axios({
        method: "get",
        url: baseUrl + `/user/cart`,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setCartData(res?.data?.data?.cart?.products);
      setTotalPrice(
        res?.data?.data?.cart?.products?.reduce(
          (accumulator, current) =>
            current?.product
              ? accumulator + current?.quantity * current?.varientPrice
              : accumulator + 0,
          0
        )
      );
    } catch (e) {}
  }

  const getCoupon = async () => {
    try {
      const res = await axios({
        method: "get",
        url: baseUrl + `/coupon/all`,
        headers: {
          "Content-Type": "application/json",
          // authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      setCoupons(res?.data?.data?.coupons);
    } catch (error) {}
  };

  const cc_Order = async (data) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${baseUrl}/ccavenuerequesthandler`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
        merchant_id: "2796133",
        access_code: "AVXE03KH83CH04EXHC",
      });
      const resData = await response?.text();
      console.log(
        "cc order data=================================================>",
        resData,
        response
      );
      return resData;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCreateCC_Order = async (data) => {
    console.log(data);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${baseUrl}/ccavenue-createOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
        merchant_id: "2796133",
        access_code: "AVXE03KH83CH04EXHC",
      });
      const resData = await response?.json();
      console.log(
        "create cc order data=================================================>",
        resData
      );
      return resData;
    } catch (error) {
      console.log(error);
    }
  };

  const getUser = async () => {
    try {
      const res = await axios({
        method: "get",
        url: baseUrl + "/user/getuser",
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      console.log(res);
      setShippingAddress(res?.data?.data?.shippingAddress);
      setSelectedAdd(res?.data?.data?.shippingAddress[0]);
    } catch (e) {}
  };

  useEffect(() => {
    getCart();
    getCoupon();
    getUser();
  }, []);

  const handleApply = () => {
    if (couponCode.length > 0) {
      const coupon = coupons?.find((coupon) => coupon?.code === couponCode);
      if (coupon) {
        if (coupon?.min_price <= totalPrice) {
          const discount = Number(coupon?.discount_percent);
          const discountedPrice = totalPrice * (discount / 100);
          setDiscountPrice(discountedPrice);
          // setCouponCode('');
          toast.success("Coupon code applied!");
          setValidCoupon(true);
        } else {
          toast.error(
            `Total Price Should be Greater Than ${coupon?.min_price}`
          );
        }
      } else {
        toast.error("Coupon code is not valid!");
      }
    } else {
      toast.error("Please enter a coupon code!");
    }
  };

  useEffect(() => {
    if (validCoupon) {
      const appliedCoupon = coupons.find(
        (coupon) => coupon.code === couponCode
      );
      if (appliedCoupon && appliedCoupon.min_price > totalPrice) {
        setValidCoupon(false);
        setDiscountPrice(0);
        toast.error(
          `Total Price Should be Greater Than ${appliedCoupon.min_price}`
        );
      }
    }
  }, [totalPrice, couponCode, validCoupon, coupons]);

  const handleOrder = async () => {
    // setOrderLoading(true);
    if (totalPrice > 0) {
      if (selectedAdd == "") {
        return alert("Please Select Address First.");
      }
      if (selectMethod == "") {
        return alert("Please Select Method First.");
      }
      const shipadd = selectedAdd?.address?.split(", ");
      console.log(shipadd);
      let orderAmt = (totalPrice - discountPrice)?.toFixed(2);

      var cartFilter = cartData.filter((e) => e.product !== null);
      // console.log(cartFilter)
      const createOrder = {
        products: cartFilter,
        coupon_applied: null,
        shippingAddress: selectedAdd,
        order_price: orderAmt,
        payment_mode: selectMethod,
      };
      const savedOrder = await fetchCreateCC_Order(createOrder);
      if (savedOrder?.status != "success") {
        return alert("Order Placed Failed.");
      }
      if (selectMethod === "ONLINE") {
        let ccOrder_data = {
          merchant_id: "2796133",
          access_code: "AVXE03KH83CH04EXHC",
          amount: orderAmt.toString(),
          billing_name: shipadd[0],
          billing_email: shipadd[1],
          billing_city: shipadd[4],
          billing_state: shipadd[3],
          billing_country: "India",
          billing_address: selectedAdd?.address,
          billing_zip: selectedAdd?.pincode,
          billing_tel: shipadd[2],
          delivery_name: shipadd[0],
          delivery_address: selectedAdd?.address,
          delivery_zip: selectedAdd?.pincode,
          delivery_tel: shipadd[2],
          order_id: savedOrder?.data?.order?._id,
          currency: "INR",
          redirect_url: `${baseUrl}/ccavenueresponsehandler`,
          cancel_url: `${baseUrl}/ccavenueresponsehandler`,
          language: "EN",
        };
        let body = new URLSearchParams();
        Promise.all(
          Object.keys(ccOrder_data).map((key) =>
            body.append(key, ccOrder_data[key])
          )
        );
        const res = await cc_Order(body);
        console.log(res);
        window.location.href = res;
        // setOrderLoading(false);
        // setCouponCode("");
        // setValidCoupon(false);
        // toast.success("Coming soon...");
      }else if(selectMethod==="COD"){
        updateCart()
        alert("Order Place Done.")
        setCartData(null)
      }
    }
  };

  const updateCart = async(data)=>{
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${baseUrl}/updatecart`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const resData = await response?.json();
      return resData;
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <AlertContainer />
      <section className="cart-section">
        <div className="wrapper-1"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <h2 className="cart-title">Cart Items: </h2>
              {cartData == null || cartData.length == 0 || cartData == "" ? (
                <img
                  src={emptyCart}
                  alt="Empty Cart"
                  style={{ width: "100px", height: "100px" }}
                />
              ) : (
                cartData?.map((data, index) => (
                  <CartData
                    key={index}
                    data={data}
                    setCartData={setCartData}
                    setTotalPrice={setTotalPrice}
                  />
                ))
              )}
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="cart-box">
                <h5 className="cart-box-title">Selected Address</h5>
                <h6>Address : {selectedAdd?.address}</h6>
                <h6>Pincode : {selectedAdd?.pincode}</h6>
                <button className="apply-btn" onClick={handleOpen}>
                  Change
                </button>
                <br />
                <br />
                {/* <div className="cart-box-apply-box">
                </div> */}
                <h6 className="cart-box-title">Coupons</h6>
                <div className="cart-box-apply-box">
                  <input
                    type="text"
                    className="cart-input"
                    placeholder="Enter Coupon Code"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                  {/* <p className="cart-text">{coupontext}</p>} */}
                  {/* {applyCoupon != "" ? (
                    <button className="apply-btn" onClick={()=>{
                      setApplyCoupon("")
                      setCounponText("")
                    }}>Remove</button>
                  ) : ( */}
                  <button className="apply-btn" onClick={handleApply}>
                    Apply
                  </button>
                  {/* )} */}
                </div>
                <h6 className="cart-box-title mt-3">Price Details</h6>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="cart-text m-0">Total MRP</p>
                  <p className="cart-text m-0">₹ {totalPrice}</p>
                </div>
                {/* <div className="d-flex justify-content-between align-items-center">
                  <p className="cart-text">Delivery Charge</p>
                  <p className="cart-text">₹ 0</p>
                </div> */}
                <div className="d-flex justify-content-between align-items-center">
                  <p className="cart-text">Discount On Coupon</p>
                  <p className="cart-text">- ₹ {discountPrice.toFixed(2)}</p>
                </div>
                <p className="cart-box-text">
                  {/* Free delivery on orders above ₹ 2499. */}
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="cart-box-title">Total Amount</h6>
                  <h6 className="cart-box-title">
                    ₹ {(totalPrice - discountPrice)?.toFixed(2)}
                  </h6>
                </div>
                {/* <p className="cart-text">
                  Product will be delivered to default address.
                </p> */}
                <input
                  type="radio"
                  name="paymentMethod"
                  value="cod"
                  id="cashOnDelivery"
                  onClick={() => setSelectMethod("COD")}
                />
                &nbsp;
                <label for="cashOnDelivery">Cash On Delivery</label> &nbsp;
                <input
                  type="radio"
                  name="paymentMethod"
                  value="online"
                  id="online"
                  onClick={() => setSelectMethod("ONLINE")}
                />
                &nbsp;
                <label for="online">Online</label>
                <br />
                <button
                  type="button"
                  className="payment-btn"
                  // data-bs-toggle="modal"
                  // data-bs-target="#staticBackdrop"
                  onClick={handleOrder}
                >
                  Buy
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Track Order
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Steps />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="payment-btn"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: auto,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="modal-title">Select New Address</h2>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shippingAddress?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <FormControl fullWidth>
                        <Radio
                          checked={selectedOption === item._id}
                          onChange={() => handleOptionChange(item, item._id)}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <p style={{ fontSize: "1.2rem", marginBottom: "1rem" }}>
                          {item?.address}
                        </p>
                        <p style={{ fontSize: "1.2rem", marginBottom: "1rem" }}>
                          {item?.pincode}
                        </p>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </>
  );
}

export default Cart;
