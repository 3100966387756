import React from 'react'
import card_img from '../../assets/our-category/category1.png'
import { AiOutlineArrowRight } from "react-icons/ai";

function OurCard() {
  return (
    <>
    <div className="col-sm-12 col-md-6 col-xl-4">
        <div className="our-card">
            <img src={card_img} className='our-card-img img-fluid' alt="" />
            <button className='shop-btn'>Shop Now <AiOutlineArrowRight /></button>
        </div>
    </div>
    </>
  )
}

export default OurCard