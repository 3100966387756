import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Grid, Tab } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../context/data";
import { toast } from "react-toastify";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { Typography, Box, Button } from "@mui/material";
import DetailSlider from "./DetailSlider";
import SmallLoader from "../loader/SmallLoader";
import CartContext from "../../context/CartContext/CartContext";

function ProductDetail() {
  const cartSide = useContext(CartContext);
  const {cartData, setCartData} = cartSide
  const [showLoader, setShowLoader] = useState(false);
  const params = useParams();
  const productId = params.productId;
  const navigate = useNavigate();
  const login = localStorage.getItem("login");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [num, setNum] = useState(1);
  const [value, setValue] = useState("1");
  let incNum = () => {
    if (num < 10) {
      setNum(Number(num) + 1);
    }
  };
  let decNum = () => {
    if (num > 1) {
      setNum(num - 1);
    }
  };
  const handleChange = (e) => {
    setNum(e.target.value);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const [product, setProduct] = useState(null);
  const [activePriceVarient, setActivePriceVarient] = useState(null);
  const [available, setAvailable] = useState([]);
  async function getProduct() {
    setShowLoader(true);
    try {
      const res = await axios({
        method: "get",
        url: baseUrl + `/product/${productId}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(res);
      setShowLoader(false);
      setProduct(res?.data?.data?.product);
      setActivePriceVarient(
        res?.data?.data?.product?.priceVarient?.length > 0
          ? res?.data?.data?.product?.priceVarient[0]
          : null
      );
    } catch (e) {
      setShowLoader(false);
    }
    setShowLoader(false);
  }
  useEffect(() => {
    getProduct();
  }, [productId]);

  // console.log(product)
  // const images = [
  //   {
  //     original: product?.displayImage[0]?.url,
  //     thumbnail: product?.displayImage[0]?.url,
  //   },
  //   {
  //     original: product?.displayImage[1]?.url,
  //     thumbnail: product?.displayImage[1]?.url,
  //   },
  //   {
  //     original: product?.displayImage[2]?.url,
  //     thumbnail: product?.displayImage[2]?.url,
  //   },
  //   {
  //     original: product?.displayImage[3]?.url,
  //     thumbnail: product?.displayImage[3]?.url,
  //   },
  //   {
  //     original: product?.displayImage[4]?.url,
  //     thumbnail: product?.displayImage[4]?.url,
  //   },
  // ];

  async function AddToCart() {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const res = await axios({
          method: "post",
          url: baseUrl + `/user/cart/product/${productId}/add`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: JSON.stringify({
            variant: activePriceVarient?.varient,
            varientPrice: activePriceVarient?.price,
          }),
        });
        console.log(res);
        if (res?.status === 200) {
          setCartData(res?.data?.data?.cart?.products);
          toast.success(res?.data?.data?.message);
        }
      } catch (e) {
        console.log(e);
        if (e?.response?.data?.error?.code == 400) {
          toast.error(e?.response?.data?.error?.message);
        } else {
          toast.error("Internal server error.");
        }
      }
    } else {
      toast.info("Please Login First.");
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  }
  if (product === null) {
    return <></>;
  }
  return (
    <>
      {showLoader && <SmallLoader value={showLoader} />}
      <AlertContainer />
      <Header />
      <section className="product-detail-section">
        <div className="container">
          <Grid container rowSpacing={4} justifyContent={"center"}>
            <Grid item md={12} lg={6}>
              <div className="image-gallery-container">
                <DetailSlider allImage={product?.displayImage} />
                {/* <ImageGallery items={images} /> */}
              </div>
            </Grid>
            <Grid item md={12} lg={6}>
              <div className="product-details">
                {/* <h5 className="product-title">Suit</h5> */}
                <h6 className="product-detail-name">{product?.displayName} </h6>

                <p className="product-detail-price">
                  RS.{" "}
                  {product
                    ? activePriceVarient
                      ? activePriceVarient?.price
                      : product?.price
                    : 0}
                </p>
                <Box>
                  <Typography variant="h6" style={{ margin: "16px 0 24px 0" }}>
                    Size/Weight
                  </Typography>
                  <div
                    style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}
                  >
                    {product?.priceVarient
                      .filter((item) => item.isAvailable) // Filter out only available variants
                      .map((item, index) => (
                        <Button
                          key={index}
                          variant="outlined"
                          size="small"
                          style={{
                            borderColor:
                              activePriceVarient?.varient === item?.varient
                                ? "#2196F3"
                                : "#B0BEC5",
                            color:
                              activePriceVarient?.varient === item?.varient
                                ? "#2196F3"
                                : "#616161",
                            borderRadius: "8px",
                            padding: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => setActivePriceVarient(item)}
                        >
                          {item?.varient} - Rs.{item?.price}
                        </Button>
                      ))}
                  </div>
                </Box>
                <p className="product-detail-text pt-3">
                  <div
                    dangerouslySetInnerHTML={{ __html: product?.description }}
                  ></div>
                  {/* Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Molestiae doloribus porro quis itaque incidunt nulla magnam,
                  modi velit qui, voluptatum sint, quidem nostrum sunt eos quo
                  numquam provident ipsa aut? */}
                </p>
                {/* <div className="product-color">
                  <p>Color</p>
                  <button className="color-btn active" disabled>
                    White
                  </button>
                  <button className="color-btn">Black</button>
                </div> */}
                <div className="product-color">
                  {/* <p>Quantity</p>
                  <div className="d-flex align-items-center mt-2">
                    <button
                      className="plush-btn"
                      type="button"
                      onClick={decNum}
                    >
                      -
                    </button>
                    <input type="text" value={num} onChange={handleChange} />
                    <div className="input-group-prepend">
                      <button
                        className="plush-btn"
                        type="button"
                        onClick={incNum}
                      >
                        +
                      </button>
                    </div>
                  </div> */}
                  <button
                    className="add-to-cart-detail-btn"
                    onClick={AddToCart}
                  >
                    Add To Cart
                  </button>
                </div>
                <div className="product-block">
                  <p className="product-block-text">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product?.description1,
                      }}
                    ></div>
                  </p>
                  <p className="product-block-text-1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product?.description2,
                      }}
                    ></div>
                  </p>
                  {/* <strong>Ingredients </strong>
                  <ul className="product-block-list">
                    <li>Alchohol</li>
                    <li>Denat </li>
                    <li>Parfum</li>
                    <li>Aqua</li>
                    <li>Ethylhexyl</li>
                    <li>Methoxycinnamate </li>
                    <li>Butyl</li>
                    <li>Methoxydibenzoyl</li>
                    <li>Methane</li>
                    <li>Ethylhexyl </li>
                    <li>Salicylate</li>
                    <li>Hexyl</li>
                    <li>Cinnamal</li>
                    <li>Limonene</li>
                    <li>Linalool</li>
                    <li>Citronellol</li>
                    <li>Alchohol</li>
                    <li>Denat </li>
                    <li>Parfum</li>
                    <li>Aqua</li>
                    <li>Ethylhexyl</li>
                    <li>Methoxycinnamate </li>
                    <li>Butyl</li>
                    <li>Methoxydibenzoyl</li>
                    <li>Methane</li>
                    <li>Ethylhexyl </li>
                    <li>Salicylate</li>
                    <li>Hexyl</li>
                    <li>Cinnamal</li>
                    <li>Limonene</li>
                    <li>Linalool</li>
                    <li>Citronellol</li>
                  </ul> */}
                  {/* <p className="m-0">
                    Eau De Parfum | Natural Spray | Vaporisateur | 100 ml | 80%
                    VOL | 3.4 FL.OZ{" "}
                  </p>
                  <p style={{ color: "red" }}>
                    <strong>
                      NO RETURNS OR EXCHANGE WILL BE APPLICABLE ON THIS PRODUCT
                    </strong>
                  </p> */}
                </div>
              </div>
            </Grid>
          </Grid>
          {/* <div className="details-tabs">
            <TabContext value={value}>
              <TabList
                className="tab-list"
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Manufacturer Details"
                  style={{
                    width: "100%",
                    alignItems: "self-start",
                    textTransform: "capitalize",
                    backgroundColor: "#fff",
                  }}
                  value="1"
                />
                <Tab
                  label="Returns Policy"
                  style={{
                    width: "100%",
                    alignItems: "self-start",
                    textTransform: "capitalize",
                    backgroundColor: "#fff",
                  }}
                  value="2"
                />
                <Tab
                  label="Place Return Request"
                  style={{
                    width: "100%",
                    alignItems: "self-start",
                    textTransform: "capitalize",
                    backgroundColor: "#fff",
                  }}
                  value="3"
                />
              </TabList>
              <TabPanel value="1" className="tab-detail">
                <div className="manufacturer-detail">
                  <p className="manufacturer-detail-title">
                    <strong>Manufactured & Marketed By:</strong>
                  </p>
                  <p className="m-0"> Snitch Apparels Pvt. Ltd.</p>
                  <p>
                    {" "}
                    No.5/1,3rd Floor, Surana Arcade, 4th Cross, Lalbagh Road,
                    K.S.Garden, Bangalore-560027
                  </p>
                  <p className="mb-1">
                    <strong>Country of Origin:</strong>
                  </p>
                  <p>India</p>
                </div>
              </TabPanel>
              <TabPanel value="2" className="tab-detail">
                <div className="return-policy">
                  <p className="m-0">
                    We offer 7 days hassle-free returns. Return Policies may
                    vary based on products and promotions.
                  </p>
                  <p className="m-0">
                    {" "}
                    1) Refunds for Prepaid orders would directly be initiated to
                    source account and COD order will be refunded in the form of
                    COUPON CODE ONLY
                  </p>
                  <p className="m-0">
                    2) Defective Products, Wrong Products or Damaged Products
                    issue should be raised within 24 hrs of delivery
                  </p>
                  <p className="m-0">
                    3) All Orders wherein FREE Products included are not
                    eligible for Return
                  </p>
                  <p className="m-0">
                    For more details on our Returns Policies, please{" "}
                  </p>
                </div>
              </TabPanel>
              <TabPanel value="3" className="tab-detail">
                <div className="return-request">
                  To place any Returns Request,
                </div>
              </TabPanel>
            </TabContext>
          </div> */}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ProductDetail;
