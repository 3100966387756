import { createHashRouter } from "react-router-dom";
import App from "../App";
import AboutPage from "../pages/AboutPage";
import ProductPage from "../pages/ProductPage";
import ProductDetail from "../components/product-detail/ProductDetail";
import BlogPage from "../pages/BlogPage";
import ProfilePage from "../pages/ProfilePage";
import Login from "../pages/Login";
import SignIn from "../pages/SignIn";
import BlogDetail from "../pages/BlogDetail";
import Cart from "../pages/Cart";
import Steps from "../pages/Stepper";
import ProductCate from "../pages/ProductCate";
import Wishlist from "../pages/Wishlist";

const router = createHashRouter([
    {
        path: "/",
        element: <App />
    },
    {
        path: "/about-us",
        element: <AboutPage />
    },
    {
        path: "/product",
        element: <ProductPage />
    },
    {
        path: "/product/:category",
        element: <ProductPage />
    },
    {
        path: "/product/:id",
        element: <ProductCate />
    },
    {
        path: "/product/product-detail/:productId",
        element: <ProductDetail />
    },
    {
        path: "/wishlist",
        element: <Wishlist />
    },
    {
        path: "/blog",
        element: <BlogPage />
    },
    {
        path: "/profile",
        element: <ProfilePage />
    },
    {
        path: "/blog/blog-detail/:id",
        element: <BlogDetail />
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/sign-in",
        element: <SignIn />
    },
    {
        path: "/checkout",
        element: <Steps />
    },
    {
        path: "/cart",
        element: <Cart />
    },
])

export default router;