import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../context/data";
import star from "../assets/arrivals/star.svg";
import like from "../assets/arrivals/Heart.png";
import liked from "../assets/arrivals/liked-btn.svg";

import emptyImg from "../assets/empty-wishlist.png";

function Wishlist() {
  const [filterByCat, setWishlistData] = useState([]);
  const wishlist = async () => {
    // const token = localStorage.getItem("token");
    // if(token){
    //   const res = await axios({
    //     method: "get",
    //     url: baseUrl + `/user/wishlist`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });
    //   setWishlistData(res?.data?.data?.wishlist?.products);
    // }else{
    const data = JSON.parse(localStorage.getItem("wishlistItems"));
    setWishlistData(data);
    // }
  };

  useEffect(() => {
    wishlist();
  }, []);

  const isProductInWishlist = (wishlist, productId) => {
    return wishlist.some((item) => item._id === productId);
  };

  const handleaddtowishlist = async (type, product) => {
    // const token = localStorage.getItem("token")
    // if(token){
    //   const res = await axios({
    //     method:"POST",
    //     url:baseUrl+`/user/wishlist/${product?._id}/${type}`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   if(res?.status==200){
    //     wishlist()
    //   }
    // }else{
    const storageKey = "wishlistItems";
    const storedItems = JSON.parse(localStorage.getItem(storageKey)) || [];
    if (
      (type === "add" && !isProductInWishlist(storedItems, product._id)) ||
      (type === "delete" && isProductInWishlist(storedItems, product._id))
    ) {
      const updatedItems =
        type === "add"
          ? [...storedItems, product]
          : storedItems.filter((item) => item._id !== product._id);
      localStorage.setItem(storageKey, JSON.stringify(updatedItems));
    }
    wishlist();
    // }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <section className="wishlist-section">
        {filterByCat === null ? (
          <div className="text-center">
            <img src={emptyImg} alt="" className="img-fluid" />
          </div>
        ) : (
          <div className="shop-items">
            {filterByCat?.map((e) => {
              return (
                <div className="shop-card">
                  <div className="like-btn">
                    {/* <img src={like} alt="" /> */}
                    <img
                      src={liked}
                      alt="Liked"
                      onClick={() => {
                        handleaddtowishlist("delete", e);
                      }}
                    />
                  </div>
                  <Link to={`/product/product-detail/${e._id}`}>
                    <img
                      className="shop-img"
                      src={e?.displayImage[0]?.url}
                      alt=""
                    />
                  </Link>
                  <div className="shop-card-content">
                    <p className="shop-title">{e?.displayName}</p>
                    {/* <div className="d-flex align-items-center">
                      <p className="brand-name m-0">{e?.brand_title}</p>
                      <div className="d-flex align-items-center">
                        <p className="shop-rating m-0">4.4 </p>
                        <img src={star} className="mb-3" alt="" />
                      </div>
                    </div> */}
                    {/* <div className="d-flex align-items-center">
                      <p className="m-0 shop-price">Rs. {e.price}</p>
                      <p className="m-0 shop-del">
                        <del>Rs. {((e?.price * 100) / 70).toFixed(0)}</del>
                      </p>
                      <p className="m-0 shop-off">(30% off)</p>
                    </div> */}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </section>
      <Footer />
    </>
  );
}

export default Wishlist;
