import React from 'react'
import circle_img from '../../assets/catalogues/Mask group.png'
import arrow from '../../assets/catalogues/arrow-btn.png'
import { useNavigate } from 'react-router-dom'

function CatalogCard({data}) {
  const navigate = useNavigate();
  function handleOnCategory(query) {
    console.log("object")
    navigate(`/product/${query}`)
  }
  return (
    <>
    <div className="catalog-card" onClick={()=>handleOnCategory(data?.name)} >
        <img src={data?.displayImage?.url}  className='circle-img' alt="" />
        <p className="catalog-title">{data?.name}</p>
        {/* <div className="catalog-shop-btn d-flex align-items-center">
          <button>Shop Now</button>
          <img src={arrow}  alt="" />
        </div> */}
    </div>
    </>
  )
}

export default CatalogCard