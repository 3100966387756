import './App.css';
import HomePage from './pages/HomePage';

function App() {
  return (
   <>
   <HomePage />
   </>
  );
}

export default App;
