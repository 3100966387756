import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { AiOutlineArrowRight } from "react-icons/ai";
import about_img from "../assets/about-img.svg";
import wrapper_top from "../assets/our-category/title-top.svg";
import wrapper_bottom from "../assets/our-category/title-bottom.svg";
import OurCard from "../components/our-category/OurCard";
import HeroSlider from "../components/hero-slider/HeroSlider";
import ArrivalSlider from "../components/arrival-slider/ArrivalSlider";
import CatalogSlider from "../components/catalog-slider/CatalogSlider";
import CustomerSlider from "../components/customer-slider/CustomerSlider";
import ServicesCard from "../components/services-card/ServicesCard";
import services1 from "../assets/services/services-1.png";
import services2 from "../assets/services/services-2.png";
import star from "../assets/arrivals/star.svg";
import like from "../assets/arrivals/Heart.png";
import liked from "../assets/arrivals/liked-btn.svg";
import Aos from "aos";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../context/data";

function HomePage() {
  const [product, setProduct] = useState(null);
  const [wishlistData, setWishlistData] = useState([]);
  async function getProduct() {
    try {
      const res = await axios({
        method: "get",
        // url: baseUrl + "/product/all",
        url: baseUrl + "/product/get/featured",
        headers: {
          "Content-Type": "application/json",
        },
      });
      setProduct(res?.data?.data?.products);
    } catch (e) {}
  }

  const wishlist = async () => {
    // const token = localStorage.getItem("token");
    // if(token){
    //   const res = await axios({
    //     method: "get",
    //     url: baseUrl + `/user/wishlist`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });
    //   setWishlistData(res?.data?.data?.wishlist?.products);
    // }else{
    const data = JSON.parse(localStorage.getItem("wishlistItems"));
    setWishlistData(data);
    // }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    getProduct();
    wishlist();
    window.scrollTo(0, 0);
    Aos.init();
  }, []);
  const isWhishlist = (productId) => {
    return wishlistData?.some((product) => product._id === productId);
  };
  const isProductInWishlist = (wishlist, productId) => {
    return wishlist.some((item) => item._id === productId);
  };

  const handleaddtowishlist = async (type, product) => {
    // const token = localStorage.getItem("token")
    // if(token){
    //   const res = await axios({
    //     method:"POST",
    //     url:baseUrl+`/user/wishlist/${product?._id}/${type}`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   if(res?.status==200){
    //     wishlist()
    //   }
    // }else{
    const storageKey = "wishlistItems";
    const storedItems = JSON.parse(localStorage.getItem(storageKey)) || [];
    if (
      (type === "add" && !isProductInWishlist(storedItems, product._id)) ||
      (type === "delete" && isProductInWishlist(storedItems, product._id))
    ) {
      const updatedItems =
        type === "add"
          ? [...storedItems, product]
          : storedItems.filter((item) => item._id !== product._id);
      localStorage.setItem(storageKey, JSON.stringify(updatedItems));
    }
    wishlist();
    // }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if (product === null) {
    return <></>;
  }
  return (
    <>
      <Header />
      <div className="hero-slider">
        <HeroSlider />
      </div>
      <section className="about-section">
        <div className="about-wrapper-1"></div>
        <div className="about-wrapper-2"></div>
        <div className="about-content text-center">
          <div
            data-aos="fade-right"
            data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="1000"
          >
            <h2 className="about-title" style={{ textTransform: "uppercase" }}>
              The Tribes' story
            </h2>
            <p className="about-text" style={{ fontWeight: "bolder" }}>
              BRAND VISION
            </p>

            <p className="about-text-">
              THE TRIBES is a brand dedicated to preserving and celebrating the
              rich cultural and culinary heritage of tribal communities.
            </p>
            <p className="about-text" style={{ fontWeight: "bolder" }}>
              BRAND MISSION
            </p>
            <p className="about-text-">
              Our mission is to offer you a curated collection of diverse and
              authentic flavors of tribal food products, Tribal Artistry and
              Tribal inspired decors sourced from indigenous farmers and
              artisans, each telling a unique story of tradition and cultural
              significance.
            </p>
            {/* <p className="about-text">
              THE TRIBES is a brand dedicated to preserving and celebrating the
              rich cultural and culinary heritage of tribal communities. <br />{" "}
              BRAND MISSION - Our mission is to offer you a curated collection of diverse and
              authentic flavors of tribal food products, Tribal Artistry and
              Tribal inspired decors sourced from indigenous farmers and
              artisans, each telling a unique story of tradition and cultural
              significance. <br />
              We take pride in establishing direct relationships with tribal
              communities, ensuring fair trade practices and providing a
              platform for their products to reach a wider market. By promoting
              ethical sourcing, we contribute to the economic empowerment of
              these communities and the preservation of their traditional
              farming methods.
            </p> */}
          </div>
          <div
            data-aos="fade-left"
            data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="1000"
          >
            <img
              src={about_img}
              className="img-fluid img-fluid-2"
              alt="about-img"
            />
          </div>
        </div>
      </section>
      <section className="our-category-section">
        <div className="our-wrapper-1"></div>
        <div className="container">
          <div className="our-content text-center">
            <img src={wrapper_top} alt="" />
            <h2 className="our-content-title">Our Categories</h2>
            <img src={wrapper_bottom} alt="" />
          </div>
          <div className="text-center-sm text-center">
            <button className="view-all-btn">
              View All <AiOutlineArrowRight className="ps-1 pb-2" />
            </button>
          </div>
          <div className="row">
            <OurCard />
            <OurCard />
            <OurCard />
          </div>
        </div>
      </section>

      <section className="catalogues-section">
        <div className="wrapper-1"></div>
        <div className="text-center">
          <h2 className="catalogues-title">Categories</h2>
          <div className="container">
            <div className="catalog-slider">
              <CatalogSlider />
            </div>
          </div>
        </div>
        <div className="wrapper-2"></div>
        <div className="wrapper-3"></div>
        <div className="wrapper-4"></div>
      </section>
      <section className="arrivals-section">
        <div className="arrival-wrapper-1"></div>
        <div
          // data-aos="fade-up"
          // data-aos-anchor-placement="center-bottom"
          // data-aos-delay="1000"
          data-aos="fade-up"
          data-aos-duration="1000"
          // data-aos-delay="100"
          data-aos-offset="50"
        >
          <div className="our-content text-center mt-3">
            <img src={wrapper_top} alt="" />
            <h2 className="our-content-title m-0">New Arrivals</h2>
            <img src={wrapper_bottom} alt="" />
          </div>
        </div>
        <div className="container">
          {/* <div className="text-center mb-4">
            <Link to="/product">
              <button className="view-all-btn">
                View All <AiOutlineArrowRight className="ps-1 pb-2" />
              </button>
            </Link>
          </div> */}
          <ArrivalSlider />
        </div>
        <div className="arrival-wrapper-2"></div>
      </section>
      <section className="shop-section">
        <div className="wrapper-1"></div>
        <div className="wrapper-2"></div>
        <div className="wrapper-3"></div>
        <div className="shop-content text-center">
          <img src={wrapper_top} alt="" />
          <h2 className="shop-content-title m-0">FEATURED PRODUCTS</h2>
          <img src={wrapper_bottom} alt="" />
        </div>
        <div className="shop-items">
        <div className="shop-cards">
          {product.map((element, index) => {
            let totalValue = element?.price * (130 / 100);
            return (
             
                <div className="shop-card" key={index}>
                  <div className="like-btn">
                    {isWhishlist(element._id) ? (
                      <img
                        src={liked}
                        alt="Liked"
                        onClick={() => {
                          handleaddtowishlist("delete", element);
                        }}
                      />
                    ) : (
                      <img
                        src={like}
                        alt="Not Liked"
                        onClick={() => {
                          handleaddtowishlist("add", element);
                        }}
                      />
                    )}
                  </div>
                  <Link to={`/product/product-detail/${element._id}`}>
                    <img
                      className="shop-img"
                      src={element?.displayImage[0]?.url}
                      alt=""
                    />
                  </Link>
                  <div className="shop-card-content">
                    <p className="shop-title">{element?.displayName}</p>
                    {/* <div className="d-flex align-items-center">
                      <p className="brand-name m-0">{element?.brand_title}</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p className="m-0 shop-price">Rs. {element.price}</p>
                    </div> */}
                  </div>
                </div>
            );
          })}
              </div>
        </div>
        <div className="text-center me-5 mb-4">
          <Link to="/product">
            <button className="view-all-btn">
              View All <AiOutlineArrowRight className="ps-1 pb-2" />
            </button>
          </Link>
        </div>
      </section>
      {/* <section className="customer-section">
        <div className="wrapper-1"></div>
        <div className="customer-content text-center">
          <img src={wrapper_top} alt="" />
          <h2 className="customer-content-title m-0">What Our Customers Say</h2>
          <img src={wrapper_bottom} alt="" />
        </div>
        <div className="customer-slider">
          <CustomerSlider />
        </div>
      </section> */}
      <section className="services-section">
        <div className="wrapper-1">
          <div className="services-box">
            <div className="row">
              <ServicesCard
                img={services1}
                title={"FREE AND FAST DELIVERY"}
                text={"Free delivery for all orders over $140"}
              />
              <ServicesCard
                img={services2}
                title={"24/7 CUSTOMER SUPPORT"}
                text={"Friendly 24/7 customer support"}
              />
              <ServicesCard
                img={services2}
                title={"MONEY BACK GUARANTEE"}
                text={"We reurn money within 30 days"}
              />
            </div>
          </div>
        </div>
        <div className="wrapper-bottom"></div>
      </section>
      <Footer />
    </>
  );
}

export default HomePage;
