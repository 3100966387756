import React from 'react'

function ServicesCard({ img, title, text }) {
  return (
    <>
    <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="services-card">
            <div className="wrapper-left"></div>
            <div className="wrapper-right"></div>
            <div className="text-center">
                <img src={img} alt="" />
                <h6 className="services-card-title">{title}</h6>
                {/* <p className="services-card-text">{text}</p> */}
            </div>
        </div>
    </div>
    </>
  )
}

export default ServicesCard