import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../context/data";
import { toast } from "react-toastify";
import AddressCartItem from "../components/address/AddressCartItem";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import OrderList from "../components/orderList/OrderList";
import SmallLoader from "../components/loader/SmallLoader";

function ProfilePage() {
  const login = localStorage.getItem("login");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [toggleSideBar, setSidebarToggle] = useState(false);
  const [address, setAddress] = useState(false);
  const [track, setTrack] = useState(false);
  const [orderHistry, setOrderHistry] = useState([]);
  const [currentOrder, setCurrentOrder] = useState([]);
  const [showLoader, setShowLoader]=useState(false)
  var initialState = {
    name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    wholeAddress: "",
    pinCode: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [allOrder, setAllOrder] = useState([]);
  const handleChange = (event) => {
    const { name, value } = event.target;
  
    // Validation Regex
    const nameRegex = /^[A-Za-z ]*$/; // Only alphabets and spaces allowed
    const cityStateRegex = /^[A-Za-z]*$/; // Only alphabets allowed
    const phoneRegex = /^\d{0,10}$/; // Up to 10 digits allowed
    const pinCodeRegex = /^\d{0,6}$/; // Up to 6 digits allowed
    const sanitizedValue = value.replace(/[^\w\s@.]/g, '');
    if ((name === "name" || name === "state") && !nameRegex.test(sanitizedValue)) return;
    if ((name === "city" ) && !cityStateRegex.test(sanitizedValue)) return;
    if (name === "phone" && !phoneRegex.test(sanitizedValue)) return;
    if (name === "pinCode" && !pinCodeRegex.test(sanitizedValue)) return;
  
    // Update form data
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: sanitizedValue,
    }));
  };
  
  

  const [shippingAddress, setShippingAddress] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const navigate = useNavigate();

  //  all console put is here to track
  console.log(currentOrder);
  console.log(orderHistry);

  function openSidebar() {
    setSidebarToggle(!toggleSideBar);
  }
  function addressDropdown() {
    setAddress(!address);
  }
  function TrackDropdown() {
    setTrack(!track);
  }
  function HandleOnLogOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("login");
    navigate("/login");
  }

  useEffect(() => {
    getUser();
    getAllOrder();
  }, []);

  async function getUser() {
    try {
      const res = await axios({
        method: "get",
        url: baseUrl + "/user/getuser",
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      console.log(res);
      setUser(res?.data?.data);
      setShippingAddress(res?.data?.data?.shippingAddress);
    } catch (e) {}
  }
  const handleAddAddress = async (e) => {
    e.preventDefault();
    
    let { name, email, phone, city, state, wholeAddress, pinCode } = formData;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,4}$/;
    if(!emailRegex.test(email)) return toast.error("Email is not Valid.")
    if (name === "") return toast.error("name is Required!");
    if (email === "") return toast.error("email is Required!");
    if (phone.length < 10) return toast.error("Mobile no Must be in 10 Digit.");
    if (city === "") return toast.error("city is Required!");
    if (state === "") return toast.error("state is Required!");
    if (wholeAddress === "") return toast.error("wholeAddress is Required!");
    if (pinCode.length < 6) return toast.error("PinCode Must be 6 Digit.");

    const data = {
      shippingAddress: [
        ...shippingAddress,
        {
          address:
            formData.name +
            ", " +
            formData.email +
            ", " +
            formData.phone +
            ", " +
            formData.state +
            ", " +
            formData.city +
            ", " +
            formData.wholeAddress,
          pincode: formData.pinCode,
          // isDefault: shippingAddress?.length === 0 ? true : false,
        },
      ],
    };
    
    if (login && login === "true") {
      setShowLoader(true)
      try {
        const result = await axios({
          method: "post",
          url: baseUrl + "/user/address/update",
          data: data,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        console.log(result);
        if (result?.data?.status === "success") {
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...user,
              shippingAddress: result?.data?.user?.shippingAddress,
            })
          );

          setUser({
            ...user,
            shippingAddress: result?.data?.data?.user?.shippingAddress,
          });
          setShippingAddress(result?.data?.data?.user?.shippingAddress);
          setFormData(initialState);
          toast.success(result?.data?.message);
        } else {
          setFormData(initialState);
          toast.error(result?.data?.error?.message);
        }
      } catch (err) {
        toast.info("Internal server error.");
      }
    } else {
      toast.error("First login");
    }
    setShowLoader(false)
  };

  const handleDefaultAddress = async (id) => {
    const data = {
      shippingAddress: shippingAddress.map((item) => {
        if (item._id === id) {
          if (item.isDefault) return item;
          else return { ...item, isDefault: true };
        } else return { ...item, isDefault: false };
      }),
    };

    try {
      const result = await axios({
        method: "post",
        url: baseUrl + "/user/address/update",
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (result?.data?.status === "success") {
        toast.success(result.message);
        console.log("sucessssss");
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...user,
            shippingAddress: result?.data?.user?.shippingAddress,
          })
        );
        setUser({
          ...user,
          shippingAddress: result?.data?.user?.shippingAddress,
        });
        setShippingAddress(result?.data?.user?.shippingAddress);
      } else {
        toast.error(result.error.message);
      }
    } catch (err) {
      toast.info("Internal server error.");
    }
    navigate("/cart");
  };

  async function handleDefaultDelete(id) {
    if (login && login === "true") {
      try {
        const res = await axios.post(
          `${baseUrl}/user/address/delete`,
          JSON.stringify({ addressId: id }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (res?.status == 200) {
          getUser();
          toast.success("Address deleted Success.");
        }
      } catch (err) {
        toast.info("Internal server error.");
      }
    }
  }

  const getAllOrder = async () => {
    try {
      const res = await axios.get(`${baseUrl}/user/order/all`, {
        headers: {
          "Content-Type": "application/josn",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const result = res?.data?.data?.orders;
      setAllOrder(result);
      handleOrderFilter(result);
    } catch (error) {}
  };

  const handleOrderFilter = (ordersData) => {
    const placedShippedOrders = ordersData.filter((order) =>
      ["placed", "shipped"].includes(order.order_status.toLowerCase())
    );
    const deliveredCancelledOrders = ordersData.filter((order) =>
      ["delivered", "cancelled by admin"].includes(
        order.order_status.toLowerCase()
      )
    );
    setCurrentOrder(placedShippedOrders);
    setOrderHistry(deliveredCancelledOrders);
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <AlertContainer />
      <Header />
      <section className="profile-section">
        <div className="wrapper-1"></div>
        <div className="wrapper-2"></div>
        <div className="container">
          <h1 className="m-0">Profile</h1>
          <div className="d-flex align-items-center mb-5">
            {/* <div>
              <div className="profile-letter">
                <img src={user?.displayImage.url} alt="" />
                <span className="m-0">{firstLetter.charAt(0)}</span>
              </div>
            </div> */}
            <div>
              <h6 className="profile-name">{user?.displayName}</h6>
              <button className="log-out-btn" onClick={HandleOnLogOut}>
                Log Out
              </button>
            </div>
          </div>
          <div className="contact-info">
            <h6 className="profile-title m-0">Contact Information</h6>
            <p className="profile-email m-0">
              <span>Phone: </span>
              {user?.phoneNumber}
            </p>
            <p className="profile-email m-0">
              <span>Email: </span>
              {user?.email}
            </p>
            <p className="profile-email m-0">
              <span>Address: </span>
              <p>
                {user
                  ? user?.shippingAddress?.map((item) => (
                      <div className="profileOptionMobile" key={item._id}>
                        <AddressCartItem
                          key={item._id}
                          addressId={item._id}
                          address={item.address}
                          handleDefaultDelete={handleDefaultDelete}
                          pincode={item.pincode}
                          defaultAddress={item.isDefault}
                          handleDefaultAddress={handleDefaultAddress}
                        />
                      </div>
                    ))
                  : null}
              </p>
            </p>
          </div>
          <div className="order-details">
            <div
              className="d-flex justify-content-between dropdown"
              onClick={() => openSidebar()}
            >
              <h4 className="dropdown-title">Orders</h4>
              <OrderList
                openSidebar={openSidebar}
                setSidebarToggle={setSidebarToggle}
                toggleSideBar={toggleSideBar}
                currentOrder={currentOrder}
                orderHistry={orderHistry}
              />
            </div>
            <div
              className="d-flex justify-content-between dropdown"
              onClick={() => addressDropdown()}
            >
              <h4 className="dropdown-title">Address</h4>
              {address === false ? (
                <i className="bi bi-caret-down-fill"></i>
              ) : (
                <i className="bi bi-caret-up-fill"></i>
              )}
            </div>
            <div
              className="dropdown-content"
              style={{
                display: address === false ? "none" : "block",
                transition: "all 0.5s",
              }}
            >
              <h2 className="address-title">
                <span>Add Address</span>
              </h2>
              <form onSubmit={handleAddAddress}>
                <div className="row">
                  <div className="col-sm-6 col-lg-4">
                    <div className="font-semibold text-[12px] md:text-[12px] l:text-[15px] xl:text-[18px] mt-2 mb-1 ">
                      Name :
                    </div>
                    <div>
                      <input
                        type="text"
                        className="profile-input"
                        placeholder="Enter Name"
                        onChange={handleChange}
                        value={formData.name}
                        name="name"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <div className="font-semibold text-[12px] md:text-[12px] l:text-[15px] xl:text-[18px] mt-2 mb-1 ">
                      Mail ID :
                    </div>
                    <div>
                      <input
                        type="text"
                        className="profile-input"
                        placeholder="Enter Email"
                        onChange={handleChange}
                        value={formData.email}
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <div className="font-semibold text-[12px] md:text-[12px] l:text-[15px] xl:text-[18px] mt-2 mb-1 ">
                      Contact number :
                    </div>
                    <div>
                      <input
                        type="text"
                        className="profile-input"
                        placeholder="Enter Contact number"
                        onChange={handleChange}
                        value={formData.phone}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="phone"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <div className="font-semibold text-[12px] md:text-[12px] l:text-[15px] xl:text-[18px] mt-2 mb-1 ">
                      City :
                    </div>
                    <div>
                      <input
                        type="text"
                        className="profile-input"
                        placeholder="Enter City"
                        onChange={handleChange}
                        value={formData.city}
                        name="city"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <div className="font-semibold text-[12px] md:text-[12px] l:text-[15px] xl:text-[18px] mt-2 mb-1 ">
                      State :
                    </div>
                    <div>
                      <input
                        type="text"
                        className="profile-input"
                        placeholder="Enter state"
                        onChange={handleChange}
                        value={formData.state}
                        name="state"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <div className="font-semibold text-[12px] md:text-[12px] l:text-[15px] xl:text-[18px] mt-1 mb-1 ">
                      Pin Code :
                    </div>
                    <div>
                      <input
                        type="text"
                        className="profile-input"
                        placeholder="Enter Pin Code"
                        onChange={handleChange}
                        name="pinCode"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        maxLength={6}
                        value={formData.pinCode}
                      />
                    </div>
                  </div>
                </div>
                <div className="font-semibold text-[12px] md:text-[12px] l:text-[15px] xl:text-[18px] mt-2 mb-1 ">
                  Whole Address :
                </div>
                <div>
                  <input
                    className="profile-input"
                    placeholder="Enter Full Address.."
                    style={{ width: "100%" }}
                    type="text"
                    name="wholeAddress"
                    value={formData.wholeAddress}
                    onChange={handleChange}
                  />
                </div>
                {/* <div className="d-flex flex-column">
                  <label htmlFor="" className="profile-address">
                    Whole Address :
                  </label>
                  <textarea
                    name=""
                    id=""
                    cols="10"
                    rows="5"
                    className="profile-input"
                    placeholder="Enter Full Address.."
                  ></textarea>
                </div> */}
                {/* <div className="d-flex flex-column">
                  <label htmlFor="" className="profile-address">
                    Pin Code :
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Pin Code"
                    className="profile-input"
                  />
                </div> */}
                {showLoader && <SmallLoader value={showLoader} />}
                <button className="submit-btn">Add Address</button>
              </form>
            </div>
            <div
              className="d-flex justify-content-between dropdown"
              onClick={() => TrackDropdown()}
            >
              <h4 className="dropdown-title">Order Status</h4>
              {address === false ? (
                <i className="bi bi-caret-down-fill"></i>
              ) : (
                <i className="bi bi-caret-up-fill"></i>
              )}
            </div>
            <div
              style={{
                display: track === false ? "none" : "block",
                transition: "all 0.5s",
              }}
            >
              <table className="table-container">
                <thead className="table-thead">
                  {/* <tr>
                    {[
                      "Quantity",
                      "Total Price",
                      "Payment",
                      "Order Status",
                    ].map((h, index) => {
                      return (
                        <th key={index} style={{ width: "100%" }}>
                          {h}
                        </th>
                      );
                    })}
                  </tr> */}
                  <tr>
                    <th style={{ width: "35%" }}>Qty</th>
                    <th style={{ width: "80%" }}>Total Price</th>
                    <th style={{ width: "100%" }}>Payment</th>
                    <th style={{ width: "100%" }}>Order Status</th>

                  </tr>
                </thead>
                <tbody className="table-tbody">
                  {allOrder.length > 0 &&
                    allOrder?.map((e, ind) => {
                      return (
                        <tr key={`order-status-${ind}-${e._id}`}>
                          <td style={{ width: "35%" }}>
                            {e?.products?.length}
                          </td>
                          <td style={{ width: "80%" }}>{e?.order_price}</td>
                          <td style={{ width: "100%" }}>{e?.payment_status}</td>
                          <td
                            style={{ width: "100%" }}
                            className={e?.order_status =="CANCELLED BY ADMIN"?"active-order-2":"active-order"}
                          >
                            {e?.order_status =="CANCELLED BY ADMIN"?"CANCELLED":e?.order_status}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProfilePage;
