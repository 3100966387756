import React from "react";
import { Link } from "react-router-dom";

function BlogCard({ blogImg, content, id, title }) {
  return (
    <>
      <div className="col-sm-12 col-md-6 col-xl-6">
        <Link to={`/blog/blog-detail/${id}`}>
          <div className="blog-card">
            <div className="blog-img text-center">
              <img src={blogImg} alt="asdasd" />
            </div>
            <div className="blog-text text-center">
              <h3 className="blog-title">{title}</h3>
              <p className="m-0">{content}</p>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}

export default BlogCard;
