import React from "react";

const AddressCartItem = ({
  addressId,
  defaultAddress,
  address,
  pincode,
  handleDefaultDelete,
  handleDefaultAddress,
  updateLoading,
}) => {
  return (
    <>
      {updateLoading ? (
        <div
          className={`cartItemContainer p-2 mb-4 poppins ${
            defaultAddress && "rounded-md border border-[#F68B29] bg-[#ffe2c8]"
          }`}
        >
          <div className="addressDetails">
            {defaultAddress && (
              <div className="font-semibold text-[#B71C1C]">
                Default Address
              </div>
            )}

            <div className="cartItemProductNameBox">
              <div className=" cartItemProductName text-[#000000] text-[12px] md:text-[14px] l:text-[16px] xl:text-[18px] mb-1.5  leading-6 mt-2">
                {address}
              </div>
            </div>
            <div className="cartItemProductSeller text-[black] text-[10px] md:text-[12px] l:text-[14px] xl:text-[16px] mb-1.5 font-semibold ">
              Pincode : {pincode}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`cartItemContainer mb-4 p-2 poppins ${
            defaultAddress && "rounded-md border border-[#F68B29] bg-[#ffe2c8]"
          } ${!defaultAddress && "cursor-pointer"}`}
        >
          <div
            className="addressDetails"
            onClick={() => handleDefaultAddress(addressId)}
          >
            {defaultAddress && (
              <div className="font-semibold text-[#B71C1C]">
                Default Address
              </div>
            )}

            <div className="cartItemProductNameBox w-100">
              <div className=" cartItemProductName text-[#000000] text-[12px] md:text-[14px] l:text-[16px] xl:text-[18px] mb-1.5  leading-6 mt-2">
                {address}
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="cartItemProductSeller text-[black] text-[10px] md:text-[12px] l:text-[14px] xl:text-[16px] mb-1.5 font-semibold ">
                Pincode : {pincode}
              </div>
            </div>
          </div>
          <button
            className="w-[fit-content] cursor-pointer rounded font-semibold p-2 mt-1 mb-6"
            style={{ backgroundColor: "#F68B29", color: "white" }}
            onClick={() => handleDefaultDelete(addressId)}
          >
            Delete
          </button>
        </div>
      )}
    </>
  );
};

export default AddressCartItem;
