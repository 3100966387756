import React, { useState } from "react";
import "./sign.css";
import { Snackbar } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { baseUrl } from "../../context/data";
import SmallLoader from "../loader/SmallLoader";
// import { CreateUser } from "../../api/ProjectsAPI";

function SignInPage() {
  const initialValue = {
    name: "",
    email: "",
    phoneNo: "",
    password: "",
  };

  const [formData, setFormData] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);
  const [isphoneValid, setIsphoneValid] = useState(true);
  const [showLoader, setShowLoader]=useState(false)
  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name=="name"){
      const isTextOnly = /^[A-Za-z\s]+$/.test(value);
      if (isTextOnly || value === "") {
        setFormData({ ...formData, [name]: value });
      }
    }else{
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleEmail = (e) => {
    const newEmail = e.target.value;
    setFormData({ ...formData, email: newEmail });
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(newEmail));
  };

  const handleMobile = (e) => {
    const newMobileNumber = e.target.value;
    const mobileRegex = /^\d{0,10}$/; // Allow a maximum of 10 digits
    if (mobileRegex.test(newMobileNumber)) {
      setFormData({ ...formData, phoneNo: newMobileNumber });
      setIsphoneValid(true);
    } else {
      setIsphoneValid(false);
    }
  };
  const navigate = useNavigate();

  async function handleOnSubmit(e) {
    e.preventDefault();
    const { email, name, phoneNo, password } = formData;
    if (email === "" && toast.error("Email is Required!")) return;
    if (name === "" && toast.error("Name is Required!")) return;
    if (phoneNo.length < 10 && toast.error("Phone Number Must be 10 digit.")) return;
    if (password === "" && toast.error("Password is Required!")) return;

    const raw = JSON.stringify({
      displayName: name,
      email: email,
      password: password,
      phoneNumber: phoneNo,
      // isAdmin: true,
    });
    setShowLoader(true)
    try {
      const res = await axios({
        method: "post",
        url: baseUrl + "/user/signup",
        data: raw,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if(res.status==200){
        const token = res?.data?.data?.token;
        localStorage.setItem("token", token);
        toast.success("Successfull.");
        setTimeout(() => {
          setFormData(initialValue);
          navigate("/login");
        }, 3000);
      }else{
        toast.error("Internal server error.")
      }
    } catch (e) {
      toast.error(e?.response?.data?.error?.message);
    }
    setShowLoader(false)
  }

  return (
    <>
      <AlertContainer />
      <section className="sign-in">
        <div className="login-1">
          <div className="sign-in-wrapper"></div>
          <div className="container">
            <div className="text-center"></div>
            <div className="sign-in-container">
              <div className="text-left">
                <h2 className="sign-in-title">Sign In</h2>
                <hr />
                <form onSubmit={handleOnSubmit}>
                  <div className="sign-in-input-container">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="input-title">
                        Email
                      </label>
                      <input
                        className="input-box"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleEmail}
                        placeholder="email"
                      />
                      {!isValid && (
                        <p style={{ color: "red" }}>Invalid email address</p>
                      )}
                    </div>
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="input-title">
                        Name
                      </label>
                      <input
                        value={formData.name}
                        name="name"
                        onChange={handleChange}
                        className="input-box"
                        type="text"
                        placeholder="Name"
                      />
                    </div>
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="input-title">
                        Phone Number
                      </label>
                      <input
                        className={`input-box ${
                          !isphoneValid ? "invalid" : ""
                        }`}
                        name="phoneNo"
                        maxLength={10}
                        value={formData.phoneNo}
                        onChange={handleMobile}
                        type="text" // Use type="text" to allow input of non-numeric characters
                        placeholder="Phone Number"
                      />
                      {!isphoneValid && (
                        <p style={{ color: "red" }}>Invalid Mobile No.</p>
                      )}
                    </div>
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="input-title">
                        Password
                      </label>
                      <input
                        className="input-box"
                        name="password"
                        autoComplete="current-password"
                        placeholder="Enter Password"
                        value={formData.password}
                        onChange={handleChange}
                        type="password"
                      />
                    </div>
                  </div>
                  {showLoader && <SmallLoader value={showLoader} />}
                  <button className="submit-btn">Submit</button>
                  <div className="login-r-box">
                    <p className="m-0">Doesn't have an account?</p>
                    <Link to="/login">Login Now</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SignInPage;
