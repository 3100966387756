import React, { useEffect } from 'react'
import BlogDetailPage from '../components/blogDetailPage/BlogDetailPage'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'

function BlogDetail() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <Header />
    <BlogDetailPage />
    <Footer />
    </>
  )
}

export default BlogDetail