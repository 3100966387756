import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import BlogCard from "../components/blog/BlogCard";
import blog from "../assets/blog/blog.png";
import axios from "axios";
import { baseUrl } from "../context/data";

const blogs = [
  {
    id: 1,
    blogImg: blog,
    date: "03/07/2023",
    content: "media & Press releases",
  },
  {
    id: 2,
    blogImg: blog,
    date: "03/07/2023",
    content: "media & Press releases",
  },
  {
    id: 3,
    blogImg: blog,
    date: "03/07/2023",
    content: "media & Press releases",
  },
  {
    id: 4,
    blogImg: blog,
    date: "03/07/2023",
    content: "media & Press releases",
  },
  {
    id: 5,
    blogImg: blog,
    date: "03/07/2023",
    content: "media & Press releases",
  },
  {
    id: 6,
    blogImg: blog,
    date: "03/07/2023",
    content: "media & Press releases",
  },
  {
    id: 7,
    blogImg: blog,
    date: "03/07/2023",
    content: "media & Press releases",
  },
];

function BlogPage() {
  const [blogData, setBlogData] = useState(null);
  async function getProduct() {
    try {
      const res = await axios({
        method: "get",
        url: baseUrl + "/blog/all",
        headers: {
          "Content-Type": "application/json",
        },
      });
      setBlogData(res.data.data.blogs);
    } catch (e) {}
  }
  useEffect(() => {
    getProduct();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  if (blogData === null) {
    return <></>;
  }
  return (
    <>
      <Header />
      <section className="blog-section">
        <div className="blog-wrapper-1"></div>
        <div className="blog-wrapper-2"></div>
        <div className="text-center">
          <h2 className="blog-head">Blogs</h2>
        </div>
        <div className="blog-container">
          <div className="container">
            <div className="row">
              {blogData.map((element, index) => {
                return (
                  <>
                    <BlogCard
                      key={element.id}
                      blogImg={element.displayImage[0].url}
                      date={element.createdAt}
                      content={element.content}
                      title={element.title}
                      id={element._id}
                    />
                  </>
                );
              })}
              {blogs.map((element, index) => {
                return (
                  <>
                    <BlogCard
                      key={element.id}
                      blogImg={element.blogImg}
                      date={element.date}
                      title={"hello"}
                      content={"Live-edge letterpress cliche, salvia fanny pack humblebrag narwhal portland. VHS man braid palo santo hoodie brunch trust fund. Bitters hashtag waistcoat fashion axe chia unicorn. Plaid fixie chambray 90's, slow-carb etsy tumeric. Cray pug you probably haven't heard of them hexagon kickstarter craft beer pork chic."}
                      id={element.id}
                    />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default BlogPage;
