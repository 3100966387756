import React, { useEffect } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
// import product1 from "../assets/about-page/product1.png";
import AboutSlider from "../components/aboutSlider/AboutSlider";
import wrapper_top from "../assets/our-category/title-top.svg";
import wrapper_bottom from "../assets/our-category/title-bottom.svg";
import profile from "../assets/customer/profile.svg";

function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Header />
      {/* <section className="about-hero">
        <div className="about-box">
          <div className="row">
            <div className="col-sm-12 col-xl-6">
              <div className="about-img">
                <img src={product1} alt="" />
              </div>
            </div>
            <div className="col-sm-12 col-xl-6">
              <h2 className="about-title">About The Tribes</h2>
              <p className="m-0 about-text">
                An online store where we celebrate the unique culture and
                heritage of tribal communities from around the world. Our
                carefully curated collection includes artisanal products that
                are handmade by skilled craftspeople using traditional
                techniques and natural materials. From Botanical remedies and
                beauty products to home decor and apparel, we offer a diverse
                range of items that are infused with the spirit and essence of
                tribal artistry
              </p>
            </div>
          </div>
        </div>
      </section> */}
      <div className="hero-slider">
      <AboutSlider />
      </div>
      <section className="customer-section">
        <div className="wrapper-1"></div>
        <div className="customer-content text-center">
          <img src={wrapper_top} alt="" />
          <h2 className="customer-content-title m-0">Founder's Note</h2>
          <img src={wrapper_bottom} alt="" />
        </div>
        <div className="customer-slider">
        <div className="customer-card-border">
        <div className="customer-card">
          <div className="text-center">
            <img src={profile} className="m-auto" alt="" />
            {/* <div className="d-flex align-content-center pt-4 justify-content-center">
              <img src={rating} alt="" />
              <p className="m-0 rating-text">4.4</p>
            </div> */}
            <p className="m-0 customer-text">
            As the founder of THE TRIBES,  I am thrilled to introduce our commitment to bringing you the finest quality products that celebrate the nutritional excellence of ancient grains, alongside a curated selection of organic oils, eco-friendly clothing and accessories, and stylish home decor. Our journey began with a vision to provide healthier, sustainable alternatives to conventional staples. We believe in the power of millet to transform diets and enhance well-being with its superior nutritional profile and gluten-free benefits. Additionally, our range of organic oils supports your wellness, while our clothing and accessories are designed with sustainability and style in mind. Our home decor collection embodies elegance and environmental consciousness. We take pride in sourcing our products from tribal communities, where they are handpicked and processed by local tribal farmers in small batches to maintain the highest quality and exclusivity. Thank you for joining us on this path to better health, sustainable living, and a more beautiful home, all while supporting and empowering tribal communities.
            </p>
          </div>
        </div>
      </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default AboutPage;
