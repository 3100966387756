import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import CatalogCard from './CatalogCard'
import axios from 'axios';
import { baseUrl } from '../../context/data';

function CatalogSlider() {
  const [allCategory, setAllCategory] = useState([]);

  const getAllCategory = async () => {
    try {
      const res = await axios({
        method: "get",
        url: baseUrl + "/product/category/all",
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(res);
      const response = res?.data?.data?.categories;
      setAllCategory(response);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);


  console.log(allCategory)


    const settings1 = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
      };


  return (
    <>
    <Slider {...settings1}  >
      {
        allCategory && allCategory.length>0 && 
        allCategory?.map(e=>(
          <div>
            <CatalogCard data={e} />
        </div>  
        ))
      }
        {/* <div>
            <CatalogCard />
        </div>
        <div>
            <CatalogCard />
        </div>
        <div>
            <CatalogCard />
        </div> */}
    </Slider>
    </>
  )
}

export default CatalogSlider