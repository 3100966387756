import React from "react";
import Slider from "react-slick";
import "./slider.css"

function DetailSlider({ allImage }) {
  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img src={allImage[i]?.url} alt={allImage[i]?.url} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Slider {...settings}>
        {allImage?.map((e, i) => {
          return (
            <>
              <div className="pd-mains">
                <img key={i} src={e?.url} alt={e?._id} className="image-gallery-image"  />
              </div>
            </>
          );
        })}
        {/* <div>
          <img src={baseUrl + "/abstract02.jpg"} />
        </div>
        <div>
          <img src={baseUrl + "/abstract03.jpg"} />
        </div>
        <div>
          <img src={baseUrl + "/abstract04.jpg"} />
        </div> */}
      </Slider>
    </>
  );
}

export default DetailSlider;
