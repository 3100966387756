import React, { useContext, useEffect, useState } from "react";
import "./header.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import smallLogo from "../../assets/tm-logo.png";
import heart from "../../assets/Wishlist.png";
import whatsapp from "../../assets/whatsapp.png";
import cart from "../../assets/Cart1.png";
import CloseIcon from "@mui/icons-material/Close";
import profile from "../../assets/user.png";
import { AiOutlineSearch } from "react-icons/ai";
import CartContext from "../../context/CartContext/CartContext";
import LoginContext from "../../context/login/LoginContext";
import axios from "axios";
import { baseUrl } from "../../context/data";

function Header() {
  const [toggleSideBar, setSidebarToggle] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const login = localStorage.getItem("login");
  const wishList = localStorage.getItem("wishlistItems");
  const wishListData = JSON.parse(wishList);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);

    // TODO: Fetch search suggestions based on inputValue and update searchSuggestions state
    // You can use an API call or local data for suggestions
  };
  const cartSide = useContext(CartContext);
  const LoginStatus = useContext(LoginContext);
  const navigate = useNavigate();
  const { loggedIn } = LoginStatus;
  const { cartSidebar, setCartSidebar, cartData, setCartData  } = cartSide;

  function openSidebar() {
    setSidebarToggle(!toggleSideBar);
  }
  function CartSidebarOpen() {
    setCartSidebar(!cartSidebar);
  }
  function HandleOnCart() {
    navigate("/cart");
  }

  async function getCart() {
    try {
      const res = await axios({
        method: "get",
        url: baseUrl + `/user/cart`,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setCartData(res?.data?.data?.cart?.products);
    } catch (e) {}
  }

  useEffect(()=>{
    getCart()
  },[])

  const handleSuggestionClick = (suggestion) => {
    setSearchInput("");
    setSearchSuggestions([]); // Assuming setSearchSuggestions is a state setter function
  };

  useEffect(() => {
    const fetchSearchSuggestions = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/product/search/query?query=${searchInput}`
        );
        const suggestions = response.data.data;
        console.log(suggestions);
        setSearchSuggestions(suggestions);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    };

    const timerId = setTimeout(() => {
      if (searchInput.trim() !== "") {
        fetchSearchSuggestions();
      }
    }, 2000); // 2000 milliseconds = 2 seconds

    return () => clearTimeout(timerId);
  }, [searchInput]);
  const whatsappNumber = "8280040822";

  function handleWatsApp() {
    window.open(`https://wa.me/${whatsappNumber}`, "_blank");
  }
  return (
    <>
    <div className="header-container">
    <div className="marq">
    <div className="return-exchange-text-desktop">
        FOR RETURN/EXCHANGE, REFUND AND ANY DIFFICULTIES IN PLACING ORDER,
        PLEASE REACH OUT TO US ON OUR WHATSAPP NO.: +91-8280040822
      </div>

    </div>
      
      <header>
        <div
          className="overlay"
          onClick={() => {
            openSidebar();
          }}
          style={toggleSideBar ? { right: 0 } : { right: "100%" }}
        ></div>
        <div
          className="cart-overlay"
          onClick={() => {
            CartSidebarOpen();
          }}
          style={cartSidebar ? { display: "block" } : { display: "none" }}
        ></div>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" className="img-fluid" />
          </Link>
          {/* <p className="small-logo">R</p> */}
        </div>
        <div
          className="list"
          style={toggleSideBar ? { right: 0 } : { right: "-200px" }}
        >
          <div
            className="logo"
            style={toggleSideBar ? { display: "block" } : { display: "none" }}
          >
            <Link to="/">
              <img src={logo} alt="" className="img-fluid img-fluid-2" />
              {/* <img src={smallLogo} className="small-logo" alt="" /> */}
            </Link>
          </div>
          <Link  to="/">Home</Link>
          <Link to="/about-us">About us</Link>
          <Link to="/product">Products</Link>
          {/* <Link>
            <div
              className="search-input"
              style={toggleSideBar ? { display: "none" } : { display: "block" }}
            >
              <input
                type="text"
                placeholder="What are you looking for?"
                value={searchInput}
                onChange={handleInputChange}
              />
              <AiOutlineSearch className="search-icon" />
              {searchSuggestions.length > 0 && (
                <ul
                  className="search-suggestions"
                  style={{ listStyleType: "none", padding: 0 }}
                >
                  {searchSuggestions.map((suggestion) => (
                    <li key={suggestion._id} style={{ textDecoration: "none" }}>
                      <Link
                        to={`/product/product-detail/${suggestion._id}`}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion.displayName}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </Link> */}
          <Link to="/wishlist">
            {toggleSideBar ? (
              "Wishlist"
            ) : (
              <div className="wishlist-btn">
                <p className="wishlist-text m-0">
                  {wishListData?.length ? wishListData?.length : "0"}
                </p>
                <i class="bi bi-heart"></i>
              </div>
            )}
          </Link>
          <Link>
            <button className="p-0" onClick={() => HandleOnCart()}>
              {toggleSideBar ? (
                "Cart"
              ) : (
                <div className="wishlist-btn">
                <p className="wishlist-text m-0" style={{right:"-10px", top:"-9px", important:true}}>
                  {cartData?.length ? cartData?.length : "0"}
                </p>
                <i class="bi bi-cart3"></i>
              </div>
                
              )}
            </button>
          </Link>
          {login && login === "true" ? (
            <Link to="/profile">
              {toggleSideBar ? (
                "Profile"
              ) : (
                <i class="bi bi-person-circle"></i>
              )}
            </Link>
          ) : (
            <Link to="/login">Login</Link>
          )}
          <Link onClick={handleWatsApp}>
            {toggleSideBar ? (
              ""
            ) : (
              <i class="bi bi-whatsapp"></i>
            )}
          </Link>
        </div>
        <div className="bras-box">
        <Link>
            <button style={{background:'none' , border:'none'}} className="p-0" onClick={() => HandleOnCart()}>
              {toggleSideBar ? (
                "Cart"
              ) : (
                <div className="wishlist-btn" >
                <p className="wishlist-text m-0" style={{background:'none' ,right:"-8px", top:"-10px", important:true}}>
                  {cartData?.length ? cartData?.length : "0"}
                </p>
                <i class="bi bi-cart3"></i>
              </div>
                
              )}
            </button>
          </Link>
          <Link className="bras-btn" onClick={handleWatsApp}>
            {toggleSideBar ? (
              <i class="bi bi-whatsapp"></i>
            ) : (
              <img
                src={whatsapp}
                style={{ height: "20px", width: "20px", marginBottom: "2px" }}
                alt=""
                className="img-fluid"
              />
            )}
          </Link>
          <button onClick={() => openSidebar()} className="bras-btn">
          <i class="bi bi-list"></i>
          </button>
        </div>
        <div
          className="cart-list"
          style={cartSidebar ? { right: 0 } : { right: "-400px" }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="m-0">Cart</h1>
            <div className="d-inline">
              <button onClick={() => CartSidebarOpen()}>
                <CloseIcon />
              </button>
            </div>
          </div>
          <div className="cart-text">
            <p>Your cart is currently empty.</p>
          </div>
        </div>
      </header>
    </div>
    </>
  );
}

export default Header;
