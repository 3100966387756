import axios from "axios";
import React, { useState } from "react";
import { baseUrl } from "../context/data";
import SmallButtonLoader from "../components/loader/SmallButtonLoader";
import { toast } from "react-toastify";

const CartData = ({ data, setCartData, setTotalPrice }) => {
  const login = localStorage.getItem("login");
  const [loadFlag, setLoadFlag] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [tempQuantity, setTempQuantity] = useState(data?.quantity)
  const modifyCart = async (productId, type, variant, varientPrice) => {
    setLoadFlag(true);
    if (login && login === "true") {
      try {
        const res = await axios({
          method: "post",
          url: `${baseUrl}/user/cart/product/${productId}/${type}`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: JSON.stringify({
            variant: variant,
            varientPrice: varientPrice,
          }),
        });
        setCartData(res?.data?.data?.cart?.products);

        setTotalPrice(
          res?.data?.data?.cart?.products?.reduce(
            (accumulator, current) =>
              current?.product
                ? accumulator + current?.quantity * current?.varientPrice
                : accumulator + 0,
            0
          )
        );
      } catch (e) {
        if (e?.response?.data?.error?.code == 400) {
          toast.error(e?.response?.data?.error?.message);
        } else {
          toast.error("Internal server error.");
        }
      }
    }
    setLoadFlag(false);
  };
  const modifyCart2 = async (
    productId,
    type,
    variant,
    varientPrice,
    quantity
  ) => {
    setLoadFlag(true);
    if (login && login === "true") {
      try {
        const res = await axios({
          method: "post",
          url: `${baseUrl}/user/cart/product/${productId}/${type}`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: JSON.stringify({
            variant: variant,
            varientPrice: varientPrice,
            quantity: quantity,
          }),
        });
        setCartData(res?.data?.data?.cart?.products);

        setTotalPrice(
          res?.data?.data?.cart?.products?.reduce(
            (accumulator, current) =>
              current?.product
                ? accumulator + current?.quantity * current?.varientPrice
                : accumulator + 0,
            0
          )
        );
      } catch (e) {
        if (e?.response?.data?.error?.code == 400) {
          toast.error(e?.response?.data?.error?.message);
        } else {
          toast.error("Internal server error.");
        }
      }
    }
    setLoadFlag(false);
  };
  return (
    <>
      <div className="cart-card">
        <div className="d-flex">
          <img
            src={data?.product?.displayImage[0]?.url}
            className="cart-card-img"
            alt=""
          />
          <div className="cart-card-content">
            <h6 className="cart-card-title">{data?.product?.displayName}</h6>
            <p className="cart-card-text m-0">Variant {data?.variant}</p>
            <p className="cart-card-text m-0">Rs. {data?.varientPrice}</p>
            <p className="cart-card-text- m-0">
              Quantity:
              {showInput ? (
                <input
                  type="number"
                  value={tempQuantity}
                  onChange={(e) => setTempQuantity(parseInt(e.target.value))}
                  onBlur={() => {
                    setShowInput(false);
                    modifyCart2(
                      data?.product?._id,
                      "modify",
                      data?.variant,
                      data?.varientPrice,
                      tempQuantity
                    );
                  }}
                  autoFocus
                  style={{
                    width: "50px", 
                    padding: "5px",
                    border: "1px solid #ccc", 
                    borderRadius: "5px", 
                    outline: "none", 
                  }}
                />
              ) : (
                <>
                  <SmallButtonLoader
                    loading={loadFlag}
                    onClick={() =>
                      data &&
                      modifyCart(
                        data?.product?._id,
                        "subtract",
                        data?.variant,
                        data?.varientPrice,
                        data?.quantity - 1
                      )
                    }
                  >
                    -
                  </SmallButtonLoader>
                  <SmallButtonLoader
                    loading={loadFlag}
                    onClick={() => {
                      setShowInput(true);
                    }}
                  >
                    {data?.quantity}
                  </SmallButtonLoader>
                  <SmallButtonLoader
                    loading={loadFlag}
                    onClick={() =>
                      data &&
                      modifyCart(
                        data?.product?._id,
                        "add",
                        data?.variant,
                        data?.varientPrice,
                        data?.quantity + 1
                      )
                    }
                  >
                    +
                  </SmallButtonLoader>
                </>
              )}
              {/* <SmallButtonLoader
                loading={loadFlag}
                onClick={() =>
                  data &&
                  modifyCart(
                    data?.product?._id,
                    "subtract",
                    data?.variant,
                    data?.varientPrice
                  )
                }
              >
                -
              </SmallButtonLoader>
              {data?.quantity}
              <SmallButtonLoader
                loading={loadFlag}
                onClick={() =>
                  data &&
                  modifyCart(
                    data?.product?._id,
                    "add",
                    data?.variant,
                    data?.varientPrice
                  )
                }
              >
                +
              </SmallButtonLoader> */}
              {/* <button
                style={{
                  border: "none",
                  borderRadius: "10px",
                  padding: "5px 14px",
                  margin: "0 10px",
                }}
                onClick={() =>
                  data &&
                  modifyCart(data?.product?._id, "subtract", data?.variant, data?.varientPrice)
                }
              >
                -
              </button>
              {data?.quantity}
              <button
                style={{
                  border: "none",
                  borderRadius: "10px",
                  padding: "5px 14px",
                  margin: "0 0 0 10px",
                }}
                onClick={() =>
                  data && modifyCart(data?.product?._id, "add",  data?.variant, data?.varientPrice)
                }
              >
                +
              </button> */}
            </p>
          </div>
        </div>
        <p className="cart-card-text-- m-0"
        >
              Quantity:
              {showInput ? (
                <input
               
                  type="number"
                  value={tempQuantity}
                  onChange={(e) => setTempQuantity(parseInt(e.target.value))}
                  onBlur={() => {
                    setShowInput(false);
                    modifyCart2(
                      data?.product?._id,
                      "modify",
                      data?.variant,
                      data?.varientPrice,
                      tempQuantity
                    );
                  }}
                  autoFocus
                  style={{
                    width: "50px", 
                    padding: "5px",
                    border: "1px solid #ccc", 
                    borderRadius: "5px", 
                    outline: "none", 
                    
                  }}
                />
              ) : (
                <>
                  <SmallButtonLoader
                 
                    loading={loadFlag}
                    onClick={() =>
                      data &&
                      modifyCart(
                        data?.product?._id,
                        "subtract",
                        data?.variant,
                        data?.varientPrice,
                        data?.quantity - 1
                      )
                    }

                  >
                    -
                  </SmallButtonLoader>


                  <SmallButtonLoader
                    loading={loadFlag}
                    onClick={() => {
                      setShowInput(true);
                    }}
                  >
                    {data?.quantity}
                  </SmallButtonLoader>


                  <SmallButtonLoader
                    loading={loadFlag}
                    onClick={() =>
                      data &&
                      modifyCart(
                        data?.product?._id,
                        "add",
                        data?.variant,
                        data?.varientPrice,
                        data?.quantity + 1
                      )
                    }
                  >
                    +
                  </SmallButtonLoader>
                </>
              )}
              {/* <SmallButtonLoader
                loading={loadFlag}
                onClick={() =>
                  data &&
                  modifyCart(
                    data?.product?._id,
                    "subtract",
                    data?.variant,
                    data?.varientPrice
                  )
                }
              >
                -
              </SmallButtonLoader>
              {data?.quantity}
              <SmallButtonLoader
                loading={loadFlag}
                onClick={() =>
                  data &&
                  modifyCart(
                    data?.product?._id,
                    "add",
                    data?.variant,
                    data?.varientPrice
                  )
                }
              >
                +
              </SmallButtonLoader> */}
              {/* <button
                style={{
                  border: "none",
                  borderRadius: "10px",
                  padding: "5px 14px",
                  margin: "0 10px",
                }}
                onClick={() =>
                  data &&
                  modifyCart(data?.product?._id, "subtract", data?.variant, data?.varientPrice)
                }
              >
                -
              </button>
              {data?.quantity}
              <button
                style={{
                  border: "none",
                  borderRadius: "10px",
                  padding: "5px 14px",
                  margin: "0 0 0 10px",
                }}
                onClick={() =>
                  data && modifyCart(data?.product?._id, "add",  data?.variant, data?.varientPrice)
                }
              >
                +
              </button> */}
            </p>
        {/* <button className="remove-cart">Remove Cart</button> */}
      </div>
    </>
  );
};

export default CartData;
