import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../context/data";

function OrderList({ openSidebar, toggleSideBar, orderHistry, currentOrder }) {
  const [cartData, setCartData] = useState(null);
  async function getCart() {
    try {
      const res = await axios({
        method: "get",
        url: baseUrl + `/user/cart`,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setCartData(res?.data?.data?.cart?.products);
    } catch (e) {}
  }
  const calculateTotalPrice = () => {
    return cartData?.reduce(
      (total, item) => total + item?.product?.price * item.quantity,
      0
    );
  };
  useEffect(() => {
    getCart();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  return (
    <>
      <div
        className="overlay"
        onClick={() => {
          openSidebar();
        }}
        style={toggleSideBar ? { display: "block" } : { display: "none" }}
      ></div>
      <div
        className="order-list"
        style={toggleSideBar ? { right: 0 } : { right: "-450px" }}
      >
        <div className="order-container">
          <h1 className="mb-3">Current Order</h1>
          <div className="order-container">
          {currentOrder?.length === 0 ? (
            <p>Your cart is empty.</p>
          ) :(
            currentOrder.map((order, orderIndex) => (
              <div className="order-card" key={`order-${order._id}`}>
                <p className="order-date">Order Date - {formatDate(order.createdAt)}</p>
                <p className="order-date mb-3">Delivery Status - <span>{order.order_status}</span> </p>
                {order.products.map((product, productIndex) => (
                  <div className="cart-card p-0" key={`product-${productIndex}`}>
                    <img
                      src={product?.product?.displayImage[0]?.url}
                      className="cart-card-img"
                      alt=""
                    />
                    <div className="cart-card-content">
                      <h6 className="cart-card-title">
                        {product?.product?.displayName}
                      </h6>
                      <p className="cart-card-text m-0">
                        Rs. {product?.product?.price}
                      </p>
                      <p className="cart-card-text m-0">
                        Quantity: {product?.quantity}
                      </p>
                    </div>
                  </div>
                ))}

              </div>
              
            ))
          )}
          </div>
        </div>
        <br />
        <hr />
        <div className="order-container">
          <h1 className="mb-3">Order History</h1>
          <div className="order-container">
          {orderHistry?.length === 0 ? 
          (
            <p>Your cart is empty.</p>
          ) : (
            orderHistry.map((order, orderIndex) => (
              <div className="order-card" key={`order-${order._id}`}>
                <p className="order-date">Order Date - {formatDate(order.createdAt)}</p>
                <p className="order-date mb-3">Delivery Status - <span> {order.order_status}</span></p>
                {order.products.map((product, productIndex) => (
                  <div className="cart-card p-0" key={`product-${productIndex}`}>
                    <img
                      src={product?.product?.displayImage[0]?.url}
                      className="cart-card-img"
                      alt=""
                    />
                    <div className="cart-card-content">
                      <h6 className="cart-card-title">
                        {product?.product?.displayName}
                      </h6>
                      <p className="cart-card-text m-0">
                        Rs. {product?.product?.price}
                      </p>
                      <p className="cart-card-text m-0">
                        Quantity: {product?.quantity}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ))
          )
          }

          </div>
        </div>
          {/* <div className="order-bottom-item">
            <div className="d-flex justify-content-between w-100">
              <h6>Total Amount:</h6>
              <h6 className="ps-5">{calculateTotalPrice() + ".00"}</h6>
            </div>
            <button className="log-out-btn mt-1 w-100 mb-2" >Checkout</button>
          </div> */}
      </div>
    </>
  );
}

export default OrderList;
