import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import { baseUrl } from "../../context/data";
import { Grid } from "@mui/material";
import ArrivalCard2 from "./ArrivalCard2";
import { Link } from "react-router-dom";

function ArrivalSlider() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const [product, setProduct] = useState(null);
  var remainingProducts = product?.slice(2);
  async function getProduct() {
    try {
      const res = await axios({
        method: "get",
        url: baseUrl + "/product/all",
        headers: {
          "Content-Type": "application/json",
        },
      });
      setProduct(res?.data?.data?.products);
    } catch (e) {}
  }
  useEffect(() => {
    getProduct();
  }, []);
  if (product === null) {
    return <></>;
  }
  return (
    <>
      <div className="new-arrival-container">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Slider {...settings}>
              {product?.map((element, index) => {
                return (
                  <>
                    <div>
                    <Link style={{textDecoration: "none"}} to="/product">
                      <div className="arrival-card px-0" key={index}>
                        {/* <div className="like-btn"></div> */}
                        <img
                          src={element?.displayImage[0]?.url}
                          className="img-fluid arrival-card-img"
                          alt=""
                        />
                        {/* <div className="arrival-card-content">
                        <p className="arrival-title">{element?.displayName}</p>
                        <div className="d-flex align-items-center">
                          <p className="brand-name m-0">
                            {element?.brand_title}
                          </p>
                          <div className="d-flex align-items-center">
                            <p className="arrival-rating m-0">4.4 </p>
                            <img src={star} className="mb-3" alt="" />
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="m-0 arrival-price">
                            Rs. {element?.price}
                          </p>
                          <p className="m-0 arrival-del">
                            <del>Rs. 700</del>
                          </p>
                          <p className="m-0 arrival-off">(30% off)</p>
                        </div>
                      </div> */}
                        {/* <button className="add-to-cart-btn">
                        <Link to={`/product/product-detail/${element?._id}`}>
                          Add to cart
                        </Link>
                      </button> */}
                      </div>
                    </Link>
                    </div>
                  </>
                );
              })}
            </Slider>
            <Slider {...settings}>
              {remainingProducts?.map((element, index) => {
                return (
                  <>
                    <div>
                    <Link style={{textDecoration: "none"}} to="/product">
                      <div className="arrival-card px-0" key={index}>
                        {/* <div className="like-btn"></div> */}
                        <img
                          src={element?.displayImage[0]?.url}
                          className="img-fluid arrival-card-img"
                          alt=""
                        />
                        {/* <div className="arrival-card-content">
                        <p className="arrival-title">{element?.displayName}</p>
                        <div className="d-flex align-items-center">
                          <p className="brand-name m-0">
                            {element?.brand_title}
                          </p>
                          <div className="d-flex align-items-center">
                            <p className="arrival-rating m-0">4.4 </p>
                            <img src={star} className="mb-3" alt="" />
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="m-0 arrival-price">
                            Rs. {element?.price}
                          </p>
                          <p className="m-0 arrival-del">
                            <del>Rs. 700</del>
                          </p>
                          <p className="m-0 arrival-off">(30% off)</p>
                        </div>
                      </div> */}
                        {/* <button className="add-to-cart-btn">
                        <Link to={`/product/product-detail/${element?._id}`}>
                          Add to cart
                        </Link>
                      </button> */}
                      </div>
                    </Link>
                    </div>
                  </>
                );
              })}
            </Slider>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Slider {...settings2}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ArrivalCard2 />
                    <ArrivalCard2 />
                  </Grid>
                  <Grid item xs={6}>
                    <ArrivalCard2 />
                    <ArrivalCard2 />
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ArrivalCard2 />
                    <ArrivalCard2 />
                  </Grid>
                  <Grid item xs={6}>
                    <ArrivalCard2 />
                    <ArrivalCard2 />
                  </Grid>
                </Grid>
              </div>
            </Slider>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ArrivalSlider;
