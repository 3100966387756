import React, { useContext, useEffect, useState } from "react";
import "./login.css";
import { Snackbar } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// import { LoginUser } from "../../api/ProjectsAPI";
import LoginContext from "../../context/login/LoginContext";
import { toast } from "react-toastify";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { baseUrl } from "../../context/data";
import SmallLoader from "../loader/SmallLoader";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const LoginStatus = useContext(LoginContext);
  const [isValid, setIsValid] = useState(true);
  const [alert, setAlert] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [email1, setEmail1] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();

  const handleEmail = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(newEmail));
  };

  async function handleOnSubmit(e) {
    e.preventDefault();
    if (email === "" && toast.error("Email is Required!")) return;
    if (password === "" && toast.error("Password is Required!")) return;

    const raw = JSON.stringify({
      email: email,
      password: password,
    });
    setShowLoader(true);
    try {
      const res = await axios({
        method: "post",
        url: baseUrl + "/user/signin",
        data: raw,
        headers: {
          "Content-Type": "application/json",
        },
      });
      const token = res?.data?.data?.user?.token;
      const userId = res?.data?.data?.user?._id;
      localStorage.setItem("token", token);
      localStorage.setItem("userId", userId);
      localStorage.setItem("login", true);
      localStorage.setItem("user", JSON.stringify(res?.data?.data?.user));
      setEmail("");
      setPassword("");
      toast.success("Login Successfull.");
      setTimeout(() => {
        LoginStatus.setLoggedIn(true);
        navigate("/");
      }, 3000);
    } catch (e) {
      toast.error(e?.response?.data?.error?.message);
    }
    // LoginUser.postUser(loginData, (response) =>{
    //   setAlert(response.message)
    //  /*  if(response.message === "User not found"){
    //     navigate("/sign-in")
    //   } */
    //   const token = response.data.token;
    //   localStorage.setItem("token", token);
    //   setAlert("Login Successful");
    //   setEmail("")
    //   setPassword("")
    //   LoginStatus.setLoggedIn(true)
    //   navigate("/profile")
    // })
    setShowLoader(false);
  }
  const handleSendOtp = async (e) => {
    e.preventDefault();
    if(email1==""){
      return toast.error("Please enter Email first.")
    }try {
      const res = await axios.post(`${baseUrl}/user/forgot/sendotp`, {email:email1}, {
        headers:{
          "Content-Type":"application/json"
        }
      })
      console.log(res)
      if(res?.status==200){
        toast.success(res?.data?.data?.message)
        setOtpSent(true);
      }
    } catch (error) {
      console.log(error?.response?.data?.error?.message)
      toast.error(error?.response?.data?.error?.message)
    }
    // setOtpSent(true);
  };
  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    if(otp==""){
      return toast.error("Please Enter OTP.")
    }
    if(newPassword==""){
      return toast.error("Please Enter Valid Password.")
    }
    try {
      const res = await axios.post(`${baseUrl}/user/forgot/submitotp`, {email:email1, otp, password:newPassword}, {
        headers:{
          "Content-Type":"application/json"
        }
      })
      console.log(res)
      if(res?.status==200){
        setOtpSent(false);
        setEmail1("")
        setNewPassword("")
        setOtp("")
        setForgotPassword(false)
        toast.success("Password Update Done.")
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AlertContainer />
      <section className="login">
        <div className="login-1">
          <div className="login-wrapper"></div>
          <Snackbar
            open={alert === "" ? false : true}
            autoHideDuration={6000}
            onClose={() => {
              setAlert("");
            }}
            severity="error"
            message={alert}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
          <div className="container">
            <div className="login-container">
              <div className="text-left">
                <h2 className="login-title">
                  {forgotPassword ? "Forgot Password" : "Login Now"}
                </h2>
                <hr />
                {forgotPassword ? (
                  <>
                    {!otpSent ? (
                      <form onSubmit={handleSendOtp}>
                        <div className="login-input-container">
                          <div className="d-flex flex-column">
                            <label htmlFor="" className="input-title">
                              Email
                            </label>
                            <input
                              value={email1}
                              onChange={(e) => {
                                setEmail1(e.target.value);
                              }}
                              className="input-box"
                              type="email"
                              name="email"
                              placeholder="email"
                              
                            />
                          </div>
                        </div>
                        {showLoader && <SmallLoader value={showLoader} />}
                        <button className="submit-btn">Send OTP</button>
                      </form>
                    ) : (
                      <form onSubmit={handlePasswordUpdate}>
                        <div className="login-input-container">
                          <div className="d-flex flex-column">
                            <label htmlFor="" className="input-title">
                              OTP
                            </label>
                            <input
                              value={otp}
                              onChange={(e) => {
                                setOtp(e.target.value);
                              }}
                              className="input-box"
                              type="text"
                              name="otp"
                              placeholder="OTP"
                              maxLength={6}
                            />
                            <label htmlFor="" className="input-title">
                              Password
                            </label>
                            <input
                              className="input-box"
                              name="password"
                              placeholder="Enter New Password"
                              autoComplete="current-password"
                              value={newPassword}
                              onChange={(e) => {
                                setNewPassword(e.target.value);
                              }}
                              type="password"
                            />
                          </div>
                        </div>
                        {showLoader && <SmallLoader value={showLoader} />}
                        <button className="submit-btn">Update Password</button>
                      </form>
                    )}
                  </>
                ) : (
                  <form onSubmit={handleOnSubmit}>
                    <div className="login-input-container">
                      <div className="d-flex flex-column">
                        <label htmlFor="" className="input-title">
                          Email
                        </label>
                        {error && email.length <= 0 ? (
                          <p
                            className="error-text"
                            // style={{
                            //   display: phoneNo.length > 0 === error ? "" : "none",
                            // }}
                          >
                            *Please enter the Email.
                          </p>
                        ) : (
                          ""
                        )}
                        <input
                          value={email}
                          onChange={(e) => {
                            handleEmail(e);
                            setSubmitted(false);
                          }}
                          className="input-box"
                          type="email"
                          name="email"
                          placeholder="email"
                        />
                        {!isValid && (
                          <p style={{ color: "red" }}>Invalid email address</p>
                        )}
                      </div>
                      <div className="d-flex flex-column">
                        <label htmlFor="" className="input-title">
                          Password
                        </label>
                        {error && password.length <= 0 ? (
                          <p
                            className="error-text"
                            // style={{
                            //   display: phoneNo.length > 0 === error ? "" : "none",
                            // }}
                          >
                            *Please enter the Password.
                          </p>
                        ) : (
                          ""
                        )}
                        <input
                          className="input-box"
                          name="password"
                          placeholder="Enter The Password"
                          autoComplete="current-password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setSubmitted(false);
                          }}
                          type="password"
                        />
                      </div>
                    </div>
                    {showLoader && <SmallLoader value={showLoader} />}
                    <button
                      className="submit-btn"
                      onClick={(e) => handleOnSubmit(e)}
                    >
                      Submit
                    </button>
                  </form>
                )}
                <div className="login-r-box">
                <button className="forrget">

                  <Link onClick={() => setForgotPassword(!forgotPassword)}>
                    {forgotPassword ? "Login" : "Forgot Password"}
                  </Link>
                </button>

                </div>
                <div className="login-r-box">
                  <p>Don't have an account?</p>
                  <Link to="/sign-in">Register Now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LoginPage;
