import React from "react";
import img from "../../assets/arrivals/product1.png";
import { Link } from "react-router-dom";

function ArrivalCard2() {
  return (
    <>
      <Link style={{ textDecoration: "none" }} to="/product">
        <div className="arrival-card pt-3 p-0">
          {/* <div className="like-btn"></div> */}
          <img
            src={img}
            style={{ objectFit: "cover" }}
            className="img-fluid arrival-card-img1"
            alt=""
          />
        </div>
      </Link>
    </>
  );
}

export default ArrivalCard2;
