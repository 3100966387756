import React, { useEffect } from 'react'
import Header from '../components/header/Header'
import SignInPage from '../components/signIn/signinPage'

function SignIn() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <Header />
    <SignInPage />
    {/* <Footer /> */}
    </>
  )
}

export default SignIn