import React from 'react'
import Header from "../components/header/Header";
import LoginPage from "../components/loginPage/loginPage";

function Login() {

  return (
    <>
    <Header />
    <LoginPage />
    {/* <Footer /> */}
    </>
  )
}

export default Login