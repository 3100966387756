import React from 'react';
import { Button, CircularProgress } from '@mui/material';

const SmallButtonLoader = ({ loading, onClick, children, ...rest }) => {
  return (
    <Button
      variant="contained"
      size="small"
      color="primary"
      disabled={loading}
      onClick={onClick}
      style={{
        borderRadius: '10px',
        padding: '1px 4px',
       marginLeft:'15px',
        color:"#272727",
        backgroundColor: '#e0e0e0',
        fontSize: '1.3rem',
      }}
      {...rest}
    >
      {loading ? (
        <CircularProgress size={20} color="inherit" />
      ) : (
        children
      )}
    </Button>
  );
};

export default SmallButtonLoader;
