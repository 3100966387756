import { useState } from "react";
import CategoryContext from "./CategoryContext";

const soapList = [
    {
      id: 1,
      categoryName: "Perfumes",
      value: "Perfumes", label: "Perfumes",
      href: "/category/perfumes",
    },
    {
        id: 2,
        categoryName: "Shirts",
        href: "/category/shirts",
        value: "Shirts", label: "Shirts",
        
    },
    {
        id: 3,
        categoryName: "T-Shirts",
        href: "/category/t_shirts",
        value: "T-Shirts", label: "T-Shirts",
    },
    {
      id: 4,
      categoryName: "Jeans",
      href: "/category/jeans",
      value: "Jeans", label: "Jeans",
    },
    {
        id: 5,
        categoryName: "Bottom Wear",
        href: "/category/bottom_wear",
        value: "Bottom Wear", label: "Bottom Wear",
    },
    {
        id: 6,
        categoryName: "Co-Order",
        href: "/category/co_order",
        value: "Co-Order", label: "Co-Order",
    },
  ];
  const priceRange = [
    {
      id: 1,
      limit: "2000",
      value: "1k to 2k",
      label: "1k to 2k",
    },
    {
      id: 2,
      limit: "3000",
      value: "2k to 3k",
      label: "2k to 3k",
    },
  ];
  const ageRange = [
    {
      id: 1,
      limit: "20",
      value: "20",
      label: "20",
    },
    {
      id: 2,
      limit: "30",
      value: "30",
      label: "30",
    },
  ];
  const colors = [
    {
      id: 1,
      value: "Red",
      label: "Red",
    },
    {
      id: 2,
      value: "Blue",
      label: "Blue",
    },
    {
      id: 3,
      value: "White",
      label: "White",
    },
    {
      id: 4,
      value: "Black",
      label: "Black",
    },
  ];

const CategoryState = (props) => {
    const [selectItem, setSelectItem] = useState("");
    const [selectedPrice, setSelectedPrice] = useState("");
    const [selectedAge, setSelectedAge] = useState("");
    const [selectedColor, setSelectedColor] = useState("")

    
    // const navigate = useNavigate();
    // useEffect(() => {
    //     if( selectItem !== null) {
    //         navigate(selectItem);
    //     }
    // }, [selectItem]);

    return(
        <CategoryContext.Provider value={{ soapList, selectItem, colors, selectedColor, setSelectedColor, setSelectItem, priceRange, ageRange, selectedPrice, setSelectedPrice, selectedAge, setSelectedAge }}>
            {props.children}
        </CategoryContext.Provider>
    )
}

export default CategoryState;