import React, { useEffect } from "react";
import Header from "../components/header/Header";
import ShopCard from "../components/shop/ShopCard";
import Footer from "../components/footer/Footer";
import "../App.css";

function ProductCate() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Header />
      <div className="product-page">
        <section className="shop-section">
          <div className="shop-items">
            <div className="row">
              <ShopCard />
              <ShopCard />
              <ShopCard />
              <ShopCard />
              <ShopCard />
              <ShopCard />
              <ShopCard />
              <ShopCard />
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default ProductCate;
