import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import CustomDropdown from "./category/CustomDropdown";
import Product1 from "../assets/product-img/Mens-Care-1.webp";
import Product2 from "../assets/product-img/Mens-Care-2.webp";
import Product3 from "../assets/product-img/Mens-Care-3.webp";
import star from "../assets/arrivals/star.svg";
import like from "../assets/arrivals/Heart.png";
import liked from "../assets/arrivals/liked-btn.svg";
// import { AiOutlineArrowRight } from "react-icons/ai";
// import wrapper_top from "../assets/our-category/title-top.svg";
// import wrapper_bottom from "../assets/our-category/title-bottom.png";
import ShopCard from "../components/shop/ShopCard";
import axios from "axios";
import { baseUrl } from "../context/data";
import { Link, useParams } from "react-router-dom";
// import Select from "react-select";
// import CategoryContext from "../context/categoryFilter/CategoryContext";
// import { useNavigate } from "react-router-dom";

function ProductPage() {
  function openCity(evt, cityName) {
    // console.log(evt, cityName)
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("category-section");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("product-item");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }

  const { category } = useParams();

  const [selectCategory, setSelectCategory] = useState("");
  const [allCategory, setAllCategory] = useState([]);
  const [data, setData] = useState([]);
  const [filterByCat, setFilterByCat] = useState([]);
  const [subCat, setSubCat] = useState("");
  const [isOpen2, setIsOpen2] = useState(false);
  const [wishlistData, setWishlistData] = useState([]);
  const [priceFilter, setPriceFilter] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 5000]);
  const getAllProduct = async () => {
    try {
      const res = await axios({
        method: "get",
        url: baseUrl + "/product/all",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const response = res?.data?.data?.products;
      setData(response);
      setFilterByCat(response);
      setPriceFilter(response);
    } catch (e) {
      console.log(e);
    }
  };

  const getAllCategory = async () => {
    try {
      const res = await axios({
        method: "get",
        url: baseUrl + "/product/category/all",
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log(res);
      const response = res?.data?.data?.categories;
      setAllCategory(response);
    } catch (e) {
      console.log(e);
    }
  };
  // console.log(selectCategory)
  useEffect(() => {
    getAllProduct();
    getAllCategory();
    wishlist();
  }, []);

  const handleFilter = () => {
    const lowcate = selectCategory.toLowerCase();
    const filtercat = data?.filter((e) => {
      const lowname = e?.product_category?.name?.toLowerCase();
      return lowname == lowcate;
    });
    setFilterByCat(filtercat);
    setPriceFilter(filtercat);
  };
  const handleFilter2 = () => {
    const lowcate = selectCategory.toLowerCase();
    const lowsub = subCat.toLowerCase();
    const filtercat = data?.filter((e) => {
      const lowname = e?.product_category?.name?.toLowerCase();
      const sub = e?.product_subCategory?.toLowerCase();
      // console.log(lowname, lowcate, sub, lowsub);
      return lowname == lowcate && sub == lowsub;
    });
    setFilterByCat(filtercat);
    setPriceFilter(filtercat);
  };

  const handlePriceFilter = (minPrice, maxPrice) => {
    const updateFilter = filterByCat.filter((e) => {
      const price = e?.price;
      return price >= minPrice && maxPrice >= price;
    });
    setFilterByCat(updateFilter);
  };

  useEffect(() => {
    handleFilter();
  }, [selectCategory]);

  useEffect(() => {
    handleFilter2();
  }, [subCat]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (category) {
        console.log("hiii", category);
        setSelectCategory(category);
      }
    }, 2000);
    return () => clearInterval(timeoutId);
  }, [category]);
  // console.log(allCategory);

  const isWhishlist = (productId) => {
    return wishlistData?.some((product) => product._id === productId);
  };

  const isProductInWishlist = (wishlist, productId) => {
    return wishlist.some((item) => item._id === productId);
  };

  const handleaddtowishlist = async (type, product) => {
    // const token = localStorage.getItem("token")
    // if(token){
    //   const res = await axios({
    //     method:"POST",
    //     url:baseUrl+`/user/wishlist/${product?._id}/${type}`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   if(res?.status==200){
    //     wishlist()
    //   }
    // }else{
    const storageKey = "wishlistItems";
    const storedItems = JSON.parse(localStorage.getItem(storageKey)) || [];
    if (
      (type === "add" && !isProductInWishlist(storedItems, product._id)) ||
      (type === "delete" && isProductInWishlist(storedItems, product._id))
    ) {
      const updatedItems =
        type === "add"
          ? [...storedItems, product]
          : storedItems.filter((item) => item._id !== product._id);
      localStorage.setItem(storageKey, JSON.stringify(updatedItems));
    }
    wishlist();
    // }
  };

  const wishlist = async () => {
    // const token = localStorage.getItem("token");
    // if(token){
    //   const res = await axios({
    //     method: "get",
    //     url: baseUrl + `/user/wishlist`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });
    //   setWishlistData(res?.data?.data?.wishlist?.products);
    // }else{
    const data = JSON.parse(localStorage.getItem("wishlistItems"));
    setWishlistData(data);

    // }
  };

  const [buttonName, setButtonName] = useState("Sort By");

  const handleLowPriceFilter = () => {
    const filteredProducts = filterByCat.sort((a, b) => a.price - b.price);
    setFilterByCat(filteredProducts);
    setButtonName("Price (lowest first)");
  };

  const handleHighPriceFilter = () => {
    const filteredProducts = filterByCat.sort((a, b) => b.price - a.price);
    setFilterByCat(filteredProducts);
    setButtonName("Price (highest first)");
  };

  const filterProducts = (minPrice, maxPrice) => {
    const filteredProducts = priceFilter.filter(
      (product) => product.price >= minPrice && product.price <= maxPrice
    );
    setFilterByCat(filteredProducts);
  };

  const applyFilter = () => {
    filterProducts(priceRange[0], priceRange[1]);
  };

  const resetProducts = () => {
    setPriceRange([0, 5000]);
    filterProducts(priceRange[0], priceRange[1]);
  };
  const handleChange = (event, newPriceRange) => {
    setPriceRange(newPriceRange);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log(filterByCat);
  return (
    <>
      <Header />
      <div className="product-page">
        <section className="shop-section">
          <div className="shop-select-category">
            {allCategory?.map((element, index) => {
              return (
                <>
                  <button
                    key={index}
                    className={
                      selectCategory === element?.name
                        ? "product-item-active"
                        : "product-item"
                    }
                    onClick={(e) => {
                      openCity(e, element?._id);
                      setSelectCategory(element?.name);
                    }}
                  >
                    {element.name}
                  </button>
                </>
              );
            })}
          </div>
          {allCategory?.map((element, index) => {
            // console.log(element);
            return (
              <>
                <div id={element?._id} className="category-section">
                  <div className="d-flex">
                    <div className="category-box">
                      <h6 className="category-title">Shop By Category</h6>
                      <div className="category-border"></div>
                      <ul className="category-list">
                        {element?.subCategory?.map((sub, i) => {
                          return (
                            <>
                              <li
                                className={subCat === sub ? "active" : ""}
                                onClick={(e) => {
                                  setSubCat(sub);
                                }}
                              >
                                {sub}
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="category-scroll">
                      {/* <div className="row"> */}
                        {/* <div className="col-sm-6 col-md-6 col-lg-4"> */}
                          <img
                            src={element?.displayImage?.url}
                            className="category-img"
                            alt=""
                          />
                        {/* </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </>
            );
          })}

          <CustomDropdown
            handlePriceFilter={handlePriceFilter}
            buttonName={buttonName}
            handleLowPriceFilter={handleLowPriceFilter}
            handleHighPriceFilter={handleHighPriceFilter}
            setIsOpen2={setIsOpen2}
            isOpen2={isOpen2}
            handleChange={handleChange}
            applyFilter={applyFilter}
            priceRange={priceRange}
            resetProducts={resetProducts}
            setPriceRange={setPriceRange}
          />
          <br/>
          <br/>
          <div className="shop-cards">
            {filterByCat?.map((e) => {
              return (
                <div className="shop-card">
                  <div className="like-btn">
                    {isWhishlist(e._id) ? (
                      <img
                        src={liked}
                        alt="Liked"
                        onClick={() => {
                          handleaddtowishlist("delete", e);
                        }}
                      />
                    ) : (
                      <img
                        src={like}
                        alt="Not Liked"
                        onClick={() => {
                          handleaddtowishlist("add", e);
                        }}
                      />
                    )}
                  </div>
                  <Link to={`/product/product-detail/${e._id}`}>
                    <img
                      className="shop-img"
                      src={e?.displayImage[0]?.url}
                      alt=""
                    />
                  </Link>
                  <div className="shop-card-content">
                    <p className="shop-title">{e?.displayName}</p>
                    <div className="d-flex align-items-center">
                      {/* <p className="brand-name m-0">{e?.brand_title}</p> */}
                      {/* <div className="d-flex align-items-center">
                        <p className="shop-rating m-0">4.4 </p>
                        <img src={star} className="mb-3" alt="" />
                      </div> */}
                    </div>
                    {/* <div className="d-flex align-items-center">
                      <p className="m-0 shop-price">Rs. {e.price}</p> */}
                      {/* <p className="m-0 shop-del">
                        <del>Rs. {((e?.price * 100) / 70).toFixed(0)}</del>
                      </p>
                      <p className="m-0 shop-off">(30% off)</p> */}
                    {/* </div> */}
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default ProductPage;
